import ls from "@livesession/sdk";
import CommunicationCenterTicketRoute from "@medflyt/webapp-react/src/modules/communication/pages/CommunicationCenterTicket/CommunicationCenterTicketRoute";
import OpenCommunicationsRoute from "@medflyt/webapp-react/src/modules/communication/pages/OpenCommunications/OpenCommunicationsRoute";
import { withApp } from "@medflyt/webapp-react/src/utils";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { DayOfWeek, LocalDate } from "js-joda";
import Raven from "raven-js";
import { ComplianceDocumentReviewScreenComponent } from "../modules/compliance/components/compliance-document-review/compliance-document-review.component";
import { PrioritizedCaregiversScreenComponent } from "../modules/compliance/components/prioritized-caregivers/prioritized-caregivers.component";
import { getParameterByName } from "./getParameterByName";

/* @ngInject */
export function config($stateProvider, $urlRouterProvider, $locationProvider, $httpProvider, ngTableFilterConfigProvider, LightboxProvider) {
  $urlRouterProvider.otherwise("/app/dashboard");
  $locationProvider.html5Mode(true);
  $httpProvider.interceptors.push('authInterceptor');

  $stateProvider
    .state('app', {
      abstract: true,
      url: "/app",
      templateUrl: "admin/views/common/content.html",
      resolve: {
        connect: function(DatabaseApi) {
          return true
        }
      }
    })
    .state('app.profile', {
      url: "/profile",
      templateUrl: "admin/views/profile.html",
      data: {
        pageTitle: 'Profile'
      },
      controller: 'profileCtrl'
    })
    .state('app.payment', {
      url: "/payment",
      templateUrl: "admin/views/payment.html",
      data: {
        pageTitle: 'Payment'
      },
      controller: 'paymentCtrl',
      resolve: {
        stripe: function(angularLoad) {
          return angularLoad.loadScript('https://js.stripe.com/v2/');
        }
      }
    })
    .state('app.tutorial', {
      url: "/tutorial",
      templateUrl: "admin/views/tutorial.html",
      data: {
        pageTitle: 'Tutorial'
      },
      controller: 'tutorialCtrl'
    })
    .state('app.sweep', {
      url: "/sweepstakes",
      templateUrl: "admin/views/sweep.html",
      data: {
        pageTitle: 'SweepStakes'
      },
      controller: 'sweepCtrl'
    })
    .state('app.invite', {
      url: "/invite",
      templateUrl: "admin/views/invite.html",
      data: {
        pageTitle: 'Invite Team Members'
      },
      controller: 'inviteCtrl'
    })
    .state('app.professionals', {
      url: "/professionals",
      templateUrl: "admin/views/professionals.html",
      data: {
        pageTitle: 'Professionals'
      },
      controller: 'professionalsCtrl',
      resolve: {
        pros: function(DatabaseApi) {
          return DatabaseApi.caregivers();
        },
        offices: function($rootScope, officesService) {
          if (!officesService.offices) {
            return officesService.getOffices();
          } else {
            return officesService.offices;
          }
        }
      }
    })
    .state('app.professionals.caregivers', {
      url: "/caregivers",
      templateUrl: "admin/views/professionals.html",
      data: {
        pageTitle: 'Caregivers'
      },
      controller: 'professionalsCtrl',
      resolve: {
        pros: function(DatabaseApi) {
          return DatabaseApi.caregivers();
        }
      }
        })
      .state('app.professionals.caregivers.profile', {
            url: "/caregivers?{caregiver:int}",
            templateUrl: "admin/views/professionals.html",
            data: { pageTitle: 'Caregivers' },
            controller: 'professionalsCtrl',
          permissionKeys: ['view_caregiver_profile'],

    })
    .state('app.professionals.pendingAcceptance', {
      url: "/pendingAcceptance",
      templateUrl: "admin/views/professionals.html",
      data: {
        pageTitle: 'Pending Acceptance'
      },
      controller: 'professionalsCtrl',
      resolve: {
        pros: function(DatabaseApi) {
          return DatabaseApi.caregivers();
        }
      }
    })
    .state('app.onboarding', {
      url: "/onboarding",
      templateUrl: "admin/views/professionals-onboarding.html",
      data: {
        pageTitle: 'Onboarding'
      },
      controller: 'professionalsOnboardingCtrl'
    })
    .state('app.onboarding.pendingApplication', {
      url: "/pendingApplication",
      templateUrl: "admin/views/caregivers-pending-application.html",
      data: {
        pageTitle: 'Pending Application'
      },
      controller: 'caregiversPendingApplicationCtrl',
      resolve: {
        pros: function(DatabaseApi) {
          return DatabaseApi.caregivers();
        },
        offices: function($rootScope, officesService) {
          if (!officesService.offices) {
            return officesService.getOffices();
          } else {
            return officesService.offices;
          }
        }
      }
    })
    .state('app.onboarding.OnboardingCaregivers', {
      url: "/OnboardingCaregivers",
      component: "onboardingComponent",
      data: {
        pageTitle: 'Onboarding Caregivers'
      }
    })
    .state('app.exclusionLists', {
      url: "/exclusion-lists",
      templateUrl: "admin/views/exclusion-lists.html",
      data: {
        pageTitle: 'Exclusions'
      },
      controller: 'ExclusionListsCtrl',
    })
    .state('app.orientationCenter', {
      url: "/orientation-center",
      templateUrl: "admin/views/orientation-center.html",
      data: {
        pageTitle: 'Orientation Center'
      },
      controller: 'orientationCenterCtrl',
      resolve: {
        offices: function($rootScope, officesService) {
          if (!officesService.offices) {
            return officesService.getOffices();
          } else {
            return officesService.offices;
          }
        }
      },
    })
    .state('app.caregiversCompliance', {
      url: "/caregivers-compliance",
      templateUrl: "admin/modules/compliance/views/caregivers-compliance.html",
      data: {
        pageTitle: 'Compliance'
      },
      controller: 'caregiversComplianceCtrl',
      resolve: {
        offices: function($rootScope, officesService) {
          if (!officesService.offices) {
            return officesService.getOffices();
          } else {
            return officesService.offices;
          }
        }
      }
    })
    .state('app.caregiversTrainingCenter', {
      url: "/trainingCenter",
      templateUrl: "admin/views/professionals.html",
      data: {
        pageTitle: 'Caregivers - Training Center'
      },
      controller: 'professionalsCtrl',
      resolve: {
        pros: function(DatabaseApi) {
          return DatabaseApi.caregivers();
        }
      }
    })
    .state('app.caregiversTrainingCenterOverview', {
      url: "/caregivers/training-center-overview",
      templateUrl: "admin/views/caregivers-training-center-overview.html",
      data: {
        pageTitle: 'Caregivers - Training Center Overview'
      },
      controller: 'CaregiversTrainingCenterOverview',
      resolve: {
        pros: function(DatabaseApi) {
          return DatabaseApi.caregivers();
        },
        offices: function($rootScope, officesService) {
          if (!officesService.offices) {
            return officesService.getOffices();
          } else {
            return officesService.offices;
          }
        }
      }
    })
    .state('app.caregiversPrioritized', {
      url: "/caregivers/prioritized-caregivers",
      data: {
        pageTitle: 'Prioritized Caregivers'
      },
      component: PrioritizedCaregiversScreenComponent.$name
    })
    // SHOULD BE ORIENTATION
    // .state('app.onboarding.caregiversTrainingCenterOverview', {
    //     url: "/caregivers/training-center-overview",
    //     templateUrl: "admin/views/caregivers-training-center-overview.html",
    //     data: { pageTitle: 'Caregivers - Training Center Overview' },
    //     controller: 'CaregiversTrainingCenterOverview',
    //     resolve: {
    //         pros: function (DatabaseApi) {
    //             return DatabaseApi.caregivers();
    //         },
    //         offices: function ($rootScope, officesService) {
    //             if (!officesService.offices) {
    //                 return officesService.getOffices();
    //             } else {
    //                 return officesService.offices;
    //             }
    //         }
    //     }
    // })
    .state('app.anomalies', {
      url: "/anomalies",
      templateUrl: "admin/views/anomalies.html",
      data: {
        pageTitle: 'Caregiver Anomalies'
      },
      controller: 'anomaliesCtrl'
    })
    .state('app.docsExpirations', {
      url: "/document-expirations",
      templateUrl: "admin/views/doc-expirations.html",
      data: {
        pageTitle: 'Document Expiration'
      },
      controller: 'docsExpirationsCtrl',
      resolve: {
        pros: function(DatabaseApi) {
          return DatabaseApi.caregivers();
        }
      }
    })
    .state('app.patients', {
      url: "/patients",
      template: '<div ui-view></div>'
    })
      .state('app.patients.dashboard', {
          url: "/dashboard",
          templateUrl: "admin/views/patients.html",
          data: { pageTitle: 'Patients' },
          params: { createPatientParams: null },
          controller: 'patientsCtrl',
      })
        .state('app.patients.dashboard.profile', {
          url: "/dashboard?{patient:int}",
          templateUrl: "admin/views/patients.html",
          data: { pageTitle: 'Patients' },
          controller: 'patientsCtrl',
          permissionKeys: ['view_patient_profile']
      })
    .state('app.patients.careAndTaskManagement', {
      url: "/careAndTaskManagement",
      templateUrl: "admin/views/patient-care-and-task.html",
      data: {
        pageTitle: "Care & Task Management"
      },
      controller: 'patientCareAndTaskCtrl'
    })
    .state('app.patients.careAndTaskManagement.upcomingTasks', {
      url: "/tasks",
      templateUrl: "admin/views/patients-upcoming-tasks.html",
      data: {
        pageTitle: "Upcoming Tasks"
      },
      controller: 'patientsUpcomingTasksCtrl'
    })
    .state('app.patients.careAndTaskManagement.careManagement', {
      url: "/careManagement",
      templateUrl: "admin/views/care-management.html",
      data: {
        pageTitle: "Visits Care Management"
      },
      controller: 'careManagementCtrl'
    })
    .state('app.patients.careAndTaskManagement.certificationPeriods', {
      url: "/certificationPeriods",
      templateUrl: "admin/views/certification-periods.html",
      data: {
        pageTitle: "Certification Periods"
      },
      controller: 'certificationPeriodsCtrl'
    })
    .state('app.patients.careAndTaskManagement.patientsDocuments', {
      url: "/patientsDocuments",
      templateUrl: "admin/views/patients-documents.html",
      data: {
        pageTitle: "Patients Documents"
      },
      controller: "patientsDocumentsCtrl",
      controllerAs: "ctrl"
    })
    .state('app.patients.patientAlerts', {
      url: "/alerts",
      templateUrl: "admin/views/patient-alerts.html",
      data: {
        pageTitle: "Patient Alerts"
      },
      controller: 'patientAlertsCtrl'
    })
    .state('app.patients.medicalStatistics', {
      url: "/medical-statistics",
      templateUrl: "admin/views/patient-medical-statistics.html",
      data: {
        pageTitle: "Quality Of Care"
      },
      controller: 'patientMedicalStatisticsCtrl'
    })
    .state('app.mergePatients', {
      url: "/merge-patients",
      templateUrl: "admin/views/merge-patients.html",
      data: {
        pageTitle: 'Merge Patients'
      },
      controller: 'mergePatientsCtrl'
    })
    .state('app.patients.eligibilityChecks', {
      url: "/eligibility-checks",
      data: {
        pageTitle: "Eligibility Checks"
      },
      component: 'eligibilityChecksPage'
    })
    .state('app.addCaregiver', {
      url: "/add-caregiver",
      templateUrl: "admin/views/add-caregiver.html",
      data: {
        pageTitle: 'Add Caregiver'
      },
      controller: 'addCaregiverCtrl',
      resolve: {
        offices: function($rootScope, officesService) {
          if (!officesService.offices) {
            return officesService.getOffices();
          } else {
            return officesService.offices;
          }
        }
      }
    })
    .state('app.trainingCenter', {
      url: "/training-center",
      template: '<div ui-view class="m-sm"></div>'
    })
    .state('app.trainingCenter.enrollmentsRequests', {
      url: "/enrollments-requests",
      templateUrl: "admin/views/training-center-enrollments-requests.html",
      data: {
        pageTitle: 'Enrollments Requests'
      },
      controller: 'trainingCenterEnrollmentsRequestsCtrl'
    })
    .state('app.trainingCenter.activeBundles', {
      url: "/active-bundles",
      templateUrl: "admin/views/training-center-active-bundles.html",
      data: {
        pageTitle: 'Active Bundles'
      },
      controller: 'trainingCenterActiveBundlesCtrl',
      resolve: {
        pros: function(DatabaseApi) {
          return DatabaseApi.caregivers();
        },
        offices: function($rootScope, officesService) {
          if (!officesService.offices) {
            return officesService.getOffices();
          } else {
            return officesService.offices;
          }
        }
      }
    })
    .state('app.trainingCenter.caregiversTrainingCenterOverview', {
      url: "/caregivers/training-center-overview",
      templateUrl: "admin/views/caregivers-training-center-overview.html",
      data: {
        pageTitle: 'Caregivers - Training Center Overview'
      },
      controller: 'CaregiversTrainingCenterOverview',
      resolve: {
        pros: function(DatabaseApi) {
          return DatabaseApi.caregivers();
        },
        offices: function($rootScope, officesService) {
          if (!officesService.offices) {
            return officesService.getOffices();
          } else {
            return officesService.offices;
          }
        }
      }
    })
    .state('app.trainingCenter.liveEvents', {
      url: "/live-events",
      templateUrl: "admin/views/training-center-live-events.html",
      data: {
        pageTitle: 'Live Events'
      },
      controller: 'trainingCenterLiveEventsCtrl'
    })

    .state('app.evv', {
      url: "/evv",
      template: '<div ui-view class="m-sm"></div>'
    })
    .state('app.evv.clockedVisits', {
      url: "/clockedVisits",
      templateUrl: "admin/views/clocked-visits.html",
      data: {
        pageTitle: 'Clock in & out'
      },
      controller: 'clockedVisitsCtrl'
    })
    .state('app.evv.calls', {
      url: "/calls",
      templateUrl: "admin/views/calls.html",
      controller: 'callsCtrl'
    })
    .state('app.evv.calls.maintenance', {
      url: "/maintenance",
      templateUrl: "admin/views/calls-maintenance.html",
      controller: 'callsMaintenanceCtrl',
      resolve: {
        typeOfCalls: () => "unlinked"
      }
    })
    .state('app.evv.calls.rejected', {
      url: "/rejected",
      templateUrl: "admin/views/calls-maintenance.html",
      controller: 'callsMaintenanceCtrl',
      resolve: {
        typeOfCalls: () => "rejected"
      }
    })
    .state('app.payroll', {
      url: "/payroll",
      template: '<div ui-view class="m-sm"></div>'
    })
    .state('app.payroll.batches', {
      url: "/payrolls",
      templateUrl: "admin/views/billing-payrolls.html",
      controller: 'billingPayrollsCtrl'
    })
    .state('app.payroll.batch', {
      url: "/payrolls/:id",
      templateUrl: "admin/views/billing-payroll-details.html",
      data: {
        pageTitle: 'Payroll details'
      },
      controller: 'billingPayrollDetailsCtrl',
      params: {
        isDraft: false,
        payrollBatch: null,
        payrollBatchWithVisits: null
      }
    })
    .state('app.payroll.ptoApprovals', {
      url: "/ptoApprovals",
      templateUrl: "admin/views/pto-approvals.html",
      data: {
        pageTitle: 'PTO Approvals'
      },
      controller: 'ptoApprovalsCtrl',
      resolve: {
        offices: function($rootScope, officesService) {
          if (!officesService.offices) {
            return officesService.getOffices();
          } else {
            return officesService.offices;
          }
        }
      }
    })
    .state('app.billing.payrolls', {
      url: "/payrolls",
      templateUrl: "admin/views/billing-payrolls.html",
      controller: 'billingPayrollsCtrl'
    })
    .state('app.visits', {
      url: "/visits",
      template: '<div ui-view class="m-sm"></div>'
    })
    .state('app.visits.dashboard', {
      url: "/dashboard",
      templateUrl: "admin/views/visits-dashboard.html",
      data: {
        pageTitle: 'Visits Dashboard',
        specialClass: 'visits-remove-intercom'
      },
      controller: 'visitsCtrl',
      resolve: {
        settings: function(DatabaseApi) {
          DatabaseApi.GetSettings();
          return true;
        }
      }
    })
    .state('app.visits.upload', {
      url: "/upload",
      templateUrl: "admin/views/visits-upload.html",
      data: {
        pageTitle: 'Upload Visits',
        specialClass: 'visits-remove-intercom'
      },
      controller: 'visitFileUploadCtrl',
    })
    .state('app.visits.broadcast', {
      url: "/broadcast",
      templateUrl: "admin/views/visits-broadcast.html",
      data: {
        pageTitle: 'Broadcast Open Visits'
      },
      controller: 'visitsBroadcastCtrl'
    })
    .state('app.visitInstances', {
      url: "/visitInstances",
      templateUrl: "admin/views/visit-instances.html",
      data: {
        pageTitle: 'Visit instances'
      },
      controller: 'visitInstancesCtrl'
    })
    .state('app.dutySheets', {
      url: "/dutySheets",
      templateUrl: "admin/views/duty-sheets.html",
      data: {
        pageTitle: 'Duty Sheets'
      },
      controller: 'dutySheetsCtrl'
    })
    .state('app.dutySheetsApprovals', {
      url: "/timesheet-approval",
      templateUrl: "admin/views/e-time-sheet-approval.html",
      data: {
        pageTitle: 'Time Sheet Approval'
      },
      controller: 'eTimeSheetApprovalCtrl'
    })
    .state('app.staffingIssues', {
      url: "/staffing-issues",
      templateUrl: "admin/views/staffing-issues.html",
      data: {
        pageTitle: 'Staffing Issues'
      },
      controller: 'staffingIssuesCtrl'
    })
    .state('app.hoursUsage', {
      url: "/hours-usage",
      templateUrl: "admin/views/hours-usage.html",
      data: {
        pageTitle: "Hours Usage"
      },
      controller: "hoursUsageCtrl"
    })
    .state('app.billing', {
      url: "/billing",
      template: '<div ui-view class="m-sm"></div>'
    })
    .state('app.billing.visitsWithIssues', {
      url: "/visits-with-issues",
      templateUrl: "admin/views/billing-visits-with-issues.html",
      controller: 'billingVisitsWithIssuesCtrl'
    })
    .state('app.billing.invoices', {
      url: "/invoices",
      templateUrl: "admin/views/billing-invoices.html",
      controller: 'billingInvoicesCtrl',
      params: {
        batchId: null
      }
    })
    .state('app.billing.newInvoiceBatch', {
      url: "/new-invoice-batch",
      templateUrl: "admin/views/billing-new-invoice-batch.html",
      controller: 'billingNewInvoiceBatchCtrl'
    })
    .state('app.billing.invoice', {
      url: "/invoices/:id",
      templateUrl: "admin/views/billing-invoice-details.html",
      data: {
        pageTitle: 'Invoice'
      },
      controller: 'billingInvoiceDetailsCtrl',
      params: {
        id: null,
        invoice: null
      }
    })
    .state('app.billing.visitAdjustmentApprovals', {
      url: "/visit-adjustment-approvals",
      templateUrl: "admin/views/visit-adjustment-approvals.html",
      controller: 'billingVisitAdjustmentApprovalsCtrl'
    })
    .state('app.billing.visitsToExport', {
      url: "/visits-to-export",
      templateUrl: "admin/views/billing-visits-to-export.html",
      controller: 'billingVisitsToExportCtrl'
    })
    .state('app.billing.invoice.visit', {
      templateUrl: "admin/views/visit-instance-modal.html",
      data: {},
      controller: 'visitInstanceModalCtrl',
      params: {
        visit: {}
      }
    })
    .state('app.billing.exports', {
      url: "/exports",
      templateUrl: "admin/views/billing-exports.html",
      controller: 'billingExportsCtrl'
    })
    .state('app.billing.export', {
      url: "/exports/:id",
      templateUrl: "admin/views/billing-export-details.html",
      data: {
        pageTitle: 'Export details'
      },
      controller: 'billingExportDetailsCtrl',
      params: {
        id: null,
        export: null
      }
    })
    .state('app.billing.checks', {
      url: "/checks",
      templateUrl: "admin/views/billing-checks.html",
      controller: 'billingChecksCtrl'
    })
    .state('app.billing.check', {
      url: "/checks/:id",
      templateUrl: "admin/views/billing-check-details.html",
      data: {
        pageTitle: 'Check'
      },
      controller: 'billingCheckDetailsCtrl',
      params: {
        id: null,
        check: null
      }
    })
    .state('app.billing.uploadEdi', {
      url: "/upload-edi",
      templateUrl: "admin/views/file-uploader.html",
      controller: 'fileUploaderCtrl',
      params: {
        data: {}
      }
    })
    .state('app.billing.claimsConversion', {
      url: "/claims-conversion",
      templateUrl: "admin/views/claims-conversion.html",
      controller: 'claimsConversionCtrl',
      params: {
        data: {}
      }
    })
    .state('app.billing.ediComparison', {
      url: "/edi-comparison",
      templateUrl: "admin/views/edi-comparison.html",
      controller: 'ediComparisonCtrl'
    })
    .state('app.billing.accountsReceivable', {
      url: "/accounts-receivable",
      templateUrl: "admin/views/billing-accounts-receivable.html",
      controller: 'billingAccountsReceivableCtrl'
    })
    .state('app.patients.confirmations', {
      url: "/confirmations",
      templateUrl: "admin/views/confirmations.html",
      data: {
        pageTitle: 'Confirmations'
      },
      controller: 'confirmationsCtrl',
      resolve: {
        caregivers: function(DatabaseApi) {
          return {}; //DatabaseApi.caregivers();
        }
      }
    })
    .state('app.visitEditRequests', {
      url: "/visit-edit-requests",
      templateUrl: "admin/views/visit-edit-requests.html",
      data: {
        pageTitle: 'Visit Edit Requests'
      },
      controller: 'visitEditRequestsCtrl'
    })
    // .state('app.payroll', {
    //     url: "/payroll",
    //     templateUrl: "admin/views/payroll.html",
    //     data: { pageTitle: 'Payroll' },
    //     controller: 'payrollCtrl'
    // })

    .state('app.analyticReports', {
      url: "/analyticReports",
      templateUrl: "admin/views/reports.html",
      data: {
        pageTitle: 'Reports'
      },
      controller: 'reportsCtrl'
    })
    .state('app.backOffice', {
      url: "/back-office",
      templateUrl: "admin/views/back-office.html",
      data: {
        pageTitle: 'Back Office'
      },
      controller: 'BackOfficeCtrl'
    })
    .state('app.settings', {
      url: "/settings",
      templateUrl: "admin/views/settings.html",
      data: {
        pageTitle: 'Settings'
      },
      controller: 'settingsCtrl'
    })
    .state('app.hrSettings', {
      url: "/hr-settings",
      templateUrl: "admin/views/hr-settings.html",
      data: {
        pageTitle: 'HR Settings'
      },
      controller: 'hrSettingsCtrl'
    })
    .state('app.notesSettings', {
      url: "/notes-settings",
      templateUrl: "admin/views/entity-notes/notes-settings.html",
      data: {
        pageTitle: 'Notes Settings'
      },
      controller: 'notesSettingsCtrl'
    })
    .state('app.trainingCenterSettings', {
      url: "/training-center-settings",
      templateUrl: "admin/views/training-center-settings.html",
      data: {
        pageTitle: 'Training Center Settings'
      },
      controller: 'TrainingCenterSettingsCtrl'
    })
    .state('app.trainingCenterExempts', {
      url: "/training-center-exempts",
      templateUrl: "admin/views/training-center-exempts.html",
      data: {
        pageTitle: 'Training Center Exempts'
      },
      controller: 'TrainingCenterExemptsCtrl'
    })
    .state('app.trainingCenterClassrooms', {
      url: "/training-center-settings-classrooms",
      templateUrl: "admin/views/training-center-classrooms.html",
      data: {
        pageTitle: 'Training Center Classrooms'
      },
      controller: 'trainingCenterClassroomsCtrl'
    })
    .state('app.trainingCenterClassroomsEdit', {
      url: "/training-center-settings-classrooms/:classroomId",
      templateUrl: "admin/views/training-center-classrooms-edit.html",
      data: {
        pageTitle: 'Training Center Classroom'
      },
      params: {
        classroomId: {
          squash: true,
          value: null
        },
      },
      controller: 'trainingCenterClassroomEditCtrl'
    })
    .state('app.trainingCenterBundleSettings', {
      url: "/training-center-settings/bundles/:id/:itemId",
      templateUrl: "admin/views/training-center-settings-bundle.html",
      data: {
        pageTitle: 'Training Center Settings'
      },
      params: {
        itemId: {
          squash: true,
          value: null
        },
      },
      controller: 'TrainingCenterBundleSettingsCtrl'
    })
    .state('app.trainingCenterBundleItemSettings', {
      url: "/training-center-settings/bundles/:bundleId/bundle_items/:bundleItemId",
      templateUrl: "admin/views/training-center-settings-bundle-item.html",
      data: {
        pageTitle: 'Training Center Settings'
      },
      controller: 'TrainingCenterBundleItemSettingsCtrl'
    })
    .state('app.trainingCenterMediaLibrary', {
      url: "./training-center/settings/media-library",
      templateUrl: "admin/views/training-center-media-library.html",
      data: {
        pageTitle: 'Media Library'
      },
      controller: 'TrainingCenterMediaLibraryCtrl'
    })
    .state('app.dashboard', {
      url: "/dashboard",
      templateUrl: "admin/views/dashboard.html",
      data: {
        pageTitle: 'Dashboard'
      },
      controller: 'dashboardCtrl'
    })
    .state('app.dashboard.caregivers', {
      url: "/caregivers",
      templateUrl: "admin/views/dashboard.html",
      data: {
        pageTitle: 'Dashboard'
      },
      controller: 'dashboardCtrl'
    })
    .state('app.dashboard.online', {
      url: "/online",
      templateUrl: "admin/views/dashboard.html",
      data: {
        pageTitle: 'Dashboard'
      },
      controller: 'dashboardCtrl'
    })
    .state('app.dashboard.installations', {
      url: "/installations",
      templateUrl: "admin/views/dashboard.html",
      data: {
        pageTitle: 'Dashboard'
      },
      controller: 'dashboardCtrl'
    })
    .state('app.dashboard.visitStatistics', {
      url: "/visitStatistics",
      templateUrl: "admin/views/dashboard.html",
      data: {
        pageTitle: 'Dashboard'
      },
      controller: 'dashboardCtrl'
    })
    .state('app.dashboard.coordinatorActivity', {
      url: "/coordinatorActivity",
      templateUrl: "admin/views/dashboard.html",
      data: {
        pageTitle: 'Dashboard'
      },
      controller: 'dashboardCtrl'
    })
    .state('app.notes', {
      url: "/notes",
      templateUrl: "admin/views/notes-page.html",
      controller: 'notesCtrl',
      controllerAs: "notes",
      params: {
        tabName: null,
        noteId: null,
        mentionId: null
      }
    })
    .state('app.notes.report', {
      url: "/notes-report",
      templateUrl: "admin/views/notes-report.html",
      data: {
        pageTitle: 'Notes report'
      },
      controller: 'notesReportCtrl'
    })
    .state('app.notes.mentionsInbox', {
      url: "/mentions-inbox",
      templateUrl: "admin/views/agency-member-mentions-inbox.html",
      data: {
        pageTitle: 'Mentions Inbox'
      },
      controller: 'agencyMemberMentionsInboxCtrl',
      params: {
        noteId: null,
        mentionId: null
      }
    })
    .state('app.notes.mentionsDone', {
      url: "/mentions-done",
      templateUrl: "admin/views/agency-member-mentions-done.html",
      data: {
        pageTitle: 'Done Mentions'
      },
      controller: 'agencyMemberMentionsDoneCtrl'
    })
    .state('app.new-visit', {
      url: "/visits/new",
      templateUrl: "admin/views/new-visit.html",
      data: {
        pageTitle: 'New Visit'
      },
      controller: 'newVisitCtrl',
      resolve: {
        visit: function() {
          return true;
        },
        settings: function(DatabaseApi) {
          DatabaseApi.GetSettings();
          return true;
        }
      },
      params: {
        patient: {},
        contract: null,
        treatment: null,
        type: null,
      }
    })
    .state('app.single-visit', {
      url: "/visits/:id",
      templateUrl: "admin/views/new-visit.html",
      data: {
        pageTitle: 'Visit'
      },
      controller: 'newVisitCtrl',
      resolve: {
        visit: function(DatabaseApi, $stateParams) {
          return DatabaseApi.getVisit($stateParams.id);
        }
      },
      params: {
        patient: {},
      }
    })
    .state('app.visitDetailes', {
      url: "/visits/:id/details",
      templateUrl: "admin/views/visit-details.html",
      data: {
        pageTitle: 'Visit Details'
      },
      controller: 'visitDetailsCtrl',
      resolve: {
        visit: function(DatabaseApi, $stateParams) {
          return DatabaseApi.getVisit($stateParams.id);
        }
      },
      params: {
        visit: {}
      }
    })
    .state('app.patientDocumentBuilder', {
      url: "/patient-document-builder/:documentId",
      templateUrl: "admin/views/patient-document-builder.html",
      data: {
        pageTitle: 'Patient Document Builder',
        specialClass: 'no-intercom'
      },
      controller: 'patientDocumentBuilderCtrl'
    })
    .state('app.patientDocumentSettings', {
      url: "/patient-document-settings",
      templateUrl: "admin/views/patient-document-settings.html",
      data: {
        pageTitle: 'Patient Document Settings'
      },
      controller: 'patientDocumentSettingsCtrl'
    })
    .state('app.feed', {
      url: "/feed",
      templateUrl: "admin/views/feed.html",
      data: {
        pageTitle: 'Feed'
      },
      controller: 'feedCtrl'
    })
    .state('app.communication', {
      url: "/communication",
      templateUrl: "admin/views/communication.html",
      data: {
        pageTitle: 'Communication',
        specialClass: 'no-intercom'
      },
      params: {
        view: ''
      },
      controller: 'communicationCtrl',
      resolve: {
        offices: function($rootScope, officesService) {
          if (!officesService.offices) {
            return officesService.getOffices();
          } else {
            return officesService.offices;
          }
        }
      }
    })
    .state('app.recruitment', {
      url: "/recruitment",
      templateUrl: "admin/views/recruitment.html",
      data: {
        pageTitle: 'Recruitment'
      },
      controller: 'recruitmentCtrl'
    })
    .state('app.passportVerifier', {
      url: "/passport-verifier",
      templateUrl: "admin/views/passport-verifier.html",
      data: {
        pageTitle: 'Passport Verifier '
      },
      controller: 'passportVerifierCtrl'
    })
    .state('app.passport', {
      url: "/passport",
      templateUrl: "admin/views/medflyt-passport.html",
      data: {
        pageTitle: 'Medflyt Passport'
      },
      controller: 'medflytPassportCtrl',
      resolve: {
        offices: function($rootScope, officesService) {
          if (!officesService.offices) {
            return officesService.getOffices();
          } else {
            return officesService.offices;
          }
        }
      }
    })
    .state('app.globalSearch', {
      url: "/search-results",
      templateUrl: "admin/views/global-search-results.html",
      data: {
        pageTitle: 'Search'
      },
      params: {
        searchText: '',
        results: {
          patients: [],
          caregivers: [],
          visits: [],
          invoices: []
        },
        resultsPreview: []
      },
      controller: 'globalSearchCtrl'
    })
    .state('login', {
      url: "/login",
      data: {
        pageTitle: 'Login',
        specialClass: 'login-bg'
      },
      component: 'loginComponent'
    })
    .state('app.calendar', {
      url: "/calendar",
      templateUrl: "admin/views/test-calendar.html",
      controller: 'testCalendarCtrl'
    })
    .state('register', {
      url: "/register",
      templateUrl: "admin/views/register-v2.html",
      data: {
        pageTitle: 'Register',
        specialClass: 'login-bg'
      },
      controller: 'registerCtrl'
    })
    .state('forgot_password', {
      url: "/forgot_password",
      templateUrl: "admin/views/register.html",
      data: {
        pageTitle: 'Register',
        specialClass: 'login-bg'
      },
      controller: 'registerCtrl'
    })
    .state('app.flyers', {
      url: "/flyers",
      templateUrl: "admin/views/flyers.html",
      data: {
        pageTitle: 'Flyers'
      },
      controller: 'flyersCtrl'
    })
    .state('app.tutorials', {
      url: "/tutorials",
      templateUrl: "admin/views/tutorials.html",
      data: {
        pageTitle: 'Tutorials'
      },
      controller: 'tutorialsCtrl'
    })

    .state('app.reports', {
      url: "/reports",
      template: '<div ui-view></div>'
    })
    .state('app.reports.standard', {
      url: "/standard",
      templateUrl:'admin/views/standard-reports.html',
      data: {
        pageTitle: 'Standard reports'
      },
      controller: 'standardReportsCtrl'
    })
    .state('app.reports.standard.standardReportView', {
      url: "/:standardReportId/standard-report-view",
      templateUrl:'admin/views/standard-report-view.html',
      resolve: {
        report: function(reportService, $stateParams) {
            return reportService.getStandardReport($stateParams.standardReportId);
        }
      },
      controller: 'standardReportViewCtrl'
    })
    .state('app.reports.coronaVirusReports', {
      url: "/corona-virus-reports",
      templateUrl: "admin/views/corona-virus-reports.html",
      data: {
        pageTitle: 'COVID-19 Reports'
      },
      controller: 'coronaVirusReportsCtrl',
      resolve: {
        offices: function($rootScope, officesService) {
          if (!officesService.offices) {
            return officesService.getOffices();
          } else {
            return officesService.offices;
          }
        }
      }
    })
    .state('app.reports.savedReports', {
      url: "/saved-reports",
      templateUrl: "admin/views/saved-reports.html",
      data: {
        pageTitle: 'Saved reports'
      },
      controller: 'savedReportsCtrl'
    })
    .state('app.reports.savedReports.my', {
      url: "/my-saved-reports",
      templateUrl: "admin/views/my-saved-reports.html",
      data: {
        pageTitle: 'My reports'
      },
      controller: 'mySavedReportsCtrl'
    })
    .state('app.reports.savedReports.shared', {
      url: "/shared-saved-reports",
      templateUrl: "admin/views/shared-saved-reports.html",
      data: {
        pageTitle: 'Shared reports'
      },
      controller: 'sharedSavedReportsCtrl'
    })
    .state('app.reports.reportTool', {
      url: "/report-tool",
      templateUrl: "admin/views/main-report-tool.html",
      data: {
        pageTitle: 'Report tool'
      },
      controller: 'reportToolMain',
      params: {
        savedReportId: null,
        accessType: null,
        mySavedReportsNames: null
      }
    })

    // AGENCIES
    .state('app.agencies', {
      url: "/agencies",
      template: '<div ui-view class="m-t-sm"></div>'
    })
    .state('app.agencies.customerEngagement', {
      url: "/customer-engagement",
      templateUrl: "admin/views/customer-engagement.html",
      controller: 'customerEngagementCtrl'
    })
    .state('app.agencies.engagementKpi', {
      url: "/engagement-kpi",
      templateUrl: "admin/views/engagement-kpi.html",
      controller: 'engagementKPICtrl'
    })
    .state('app.hhax', {
      url: "/hhax-integration",
      template: '<div ui-view class="m-t-sm"></div>',
    })
    .state('app.hhax.billing', {
      url: "/billing",
      templateUrl: "admin/views/hha-integration/hha-integration-billing.html",
      data: {
        pageTitle: 'HHAX Billing Integration'
      },
      controller: 'hhaIntegrationBillingCtrl',
      resolve: {
        offices: function($rootScope, officesService) {
          if (!officesService.offices) {
            return officesService.getOffices();
          } else {
            return officesService.offices;
          }
        }
      }
    })
    .state('app.hhax.nonBilling', {
      url: "/non-billing",
      templateUrl: "admin/views/hha-integration/hha-integration-non-billing.html",
      data: {
        pageTitle: 'HHAX Non Billing Integration'
      },
      controller: 'hhaIntegrationNonBillingCtrl',
      resolve: {
        offices: function($rootScope, officesService) {
          if (!officesService.offices) {
            return officesService.getOffices();
          } else {
            return officesService.offices;
          }
        }
      }
    })
    .state('app.hhax.unlinkedPatients', {
      url: "/unlinked-patients",
      templateUrl: "admin/views/hha-integration/hha-integration-unlinked-patients.html",
      data: {
        pageTitle: 'HHAX Integration - Unlinked Patients'
      },
      controller: 'hhaIntegrationUnlinkedPatientsCtrl'
    })
    .state('app.complianceDocumentReview', {
      url: "/compliance-document-review",
      template: '<div ui-view class="m-t-sm"></div>',
    })
    .state('app.complianceDocumentReview.complianceDocumentReview', {
      url: "/compliance-document-review",
      data: {
        pageTitle: 'Compliance Document Review'
      },
      component: ComplianceDocumentReviewScreenComponent.$name
    })
    .state("app.commcenter", {
        url: "/commcenter",
        component: withApp(OpenCommunicationsRoute),
    })
    .state("app.commcenter_ticket", {
      url: "/commcenter/:ticketId",
      component: withApp(CommunicationCenterTicketRoute),
    })
    ;

  var filterAliasUrls = {
    "confirmed": "admin/views/common/confirmed.html",
    "orientation": "admin/views/common/orientation.html",
    // note: ngTable also registers a 'number' filter
    // we're overriding this alias to point to our custom template
    //"number": "path/to/your/filters/number.html"
  };
  ngTableFilterConfigProvider.setConfig({
    aliasUrls: filterAliasUrls
  });

  LightboxProvider.fullScreenMode = true;


}

export const uibModalProviderConfig = [
  "$uibModalProvider",
  function ($uibModalProvider) {
    $uibModalProvider.options.backdrop = "static";
  },
];

export const compileProviderConfig = [
  "$compileProvider",
  function ($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(file|http|https|ftp|mailto|tel|app|blob):/);
    $compileProvider.imgSrcSanitizationWhitelist(
      /^\s*(https?|local|data|chrome-extension|blob|data):/
    );
  },
];

/* @ngInject */
export function initialRun($rootScope, $state, DatabaseApi, editableOptions, Storage, Notifications, $window, $timeout, Socket, Analytics, toaster, $uibModal, VisitStatus, SweetAlert, $location, Consts, agencyMemberMentionsService, $transitions, wildcard, noteConsts, dropdownSettingsConsts) {
  var counterRetries = 0;
  const preLoginStates = ['login', 'register', 'forgot_password'];

    $transitions.onBefore({} , function(transition) {
        const permissionsKeys = transition.to().permissionKeys;
        const stateService = transition.router.stateService;
        if (permissionsKeys && !permissionsKeys.some(key => $rootScope.isPermittedByKey(key))) {
            return stateService.target('app.dashboard');
        }
    })

    // $transitions.onSuccess({} , function(transition) {
    //   if(preLoginStates.indexOf(transition.to().name) !== -1) {
    //     try {
    //       window.HubSpotConversations.widget.remove();
    //     } catch (e) {

    //     }

    //   } else {

    //     try {
    //       window.HubSpotConversations.widget.load({ widgetOpen: false });
    //     } catch (e) {

    //     }

    //   }
    // })

  var bell = new Audio('../admin/sounds/bell.mp3');
  $rootScope.comingSoon = function() {
    toaster.pop('success', 'Coming soon...');
  }
  $rootScope.commPageChatCounterCache = {};
  /*
    for(var i = 0 ; i < 100 ; i++){
      $http.get('https://medflytserver-stg.herokuapp.com/').then(function (v) {
          console.log(new Date());
        }, function (err) {
          console.log(err)
        });
    }
    */

  $rootScope.showCallsDashboardAgencies = [
    7,
    4093,
    3235,
    5149,
    5215,
    4621,
    1057,
    5182,
    5678,
    6040,
    2806,
    5644,
    6205,
    6238,
    5974,
    4357,
    6634,
    4489
  ];
  $rootScope.allLanguages = [
    'English',
    'Spanish',
    'Russian',
    'Chinese',
    'French',
    'Arabic',
    'Creole'
  ];
  $rootScope.allLanguagesIdLabel = [{
      id: 1,
      label: "English"
    },
    {
      id: 2,
      label: "Spanish"
    },
    {
      id: 3,
      label: "Chinese"
    },
    {
      id: 4,
      label: "Portuguese"
    },
    {
      id: 5,
      label: "French"
    },
    {
      id: 6,
      label: "Russian"
    },
    {
      id: 7,
      label: "Arabic"
    },
  ];

  $rootScope.singleDropdownExtraSettings = {
    showCheckAll: false
  };

  $rootScope.boostCoronavirusModal = function() {

    var PRICE_PER_SMS = Math.ceil(Consts.SEGMENT_AMOUNT * Consts.PRICE_PER_SEGMENT);
    Analytics.event("Boost clicked", {
      type: "DOWNLOAD_BOOST"
    });

    if ($rootScope.stats.caregivers === undefined) {
      return;
    }

    const activeCaregiverIds = $rootScope.stats.caregivers.ids.active;

    var modalInstance = $uibModal.open({
      templateUrl: 'admin/views/boost-modal.html',
      size: 'lg',
      controller: 'boostModalCtrl',
      resolve: {
        textAmountToBroadcast: function() {
          return activeCaregiverIds.length
        },
        priceOverallCents: function() {
          return activeCaregiverIds.length * PRICE_PER_SMS
        },
        type: function() {
          return "CORONAVIRUS_BOOST"
        }
      },
      windowTopClass: "boost-modal"
    });
    modalInstance.result.then(function(res) {
      if (res === "BOOST") {
        DatabaseApi.post('agency/' + $rootScope.agencyId + '/' + $rootScope.agencyMemberId + '/boost', {
          caregiverIds: activeCaregiverIds,
          price: PRICE_PER_SMS,
          type: "CORONAVIRUS_BOOST",
          visit: null
        }).then(function(result) {
          Analytics.event("Boost done", {
            type: "CORONAVIRUS_BOOST",
            pricePerSms: PRICE_PER_SMS
          });
          toaster.pop('success', 'Boosted successfully', 'Allow 10 minutes for sms to be sent');
        }).catch(function(err) {
          toaster.pop('error', 'Boost failed', err.data.error ? err.data.error : 'Please try again.');
        });
      }
    });
  };



  //$rootScope.pendingVisitEditRequests = 10;
  // $rootScope.showSweepStakes = !Storage.get('hide-sweep-vegas');

  $rootScope.totalNewChats = 0;
  $rootScope.visitSettings = {
    single: {},
    recuring: {}
  };

  var caregiversMap = {};

  $rootScope.setCompanyType = function(companyType) {
    if (companyType === 'Hospice' || companyType === 'SNF' || companyType === 'HOS') {
      $rootScope.companyType = 'staffing';
    } else if (!companyType || companyType === 'undefined' || companyType === 'CHHA' || companyType === 'CHH' || companyType === 'LHCSA') {
      ////console.log('home care');
      $rootScope.companyType = 'homeCare';
    } else {
      $rootScope.companyType = 'MLTC(P)';
    }
    Storage.set('company-type', companyType);
  };

  var comapnyT = Storage.set('company-type');
  $rootScope.setCompanyType(comapnyT);


  editableOptions.theme = 'bs3';
  /*
   if($rootScope.user && $rootScope.user._id){
   DatabaseApi.connect();
   }
   */



  $rootScope.stealthMode = Storage.get('stealthMode') || false;
  var userObject = Storage.getObject('user');
  if (
    angular.isDefined(userObject.authToken) &&
    (
      !$rootScope.user ||
      !$rootScope.user.email ||
      !$rootScope.user.verified
    ) &&
    (
      (
        $rootScope.user &&
        $rootScope.user.superuser
      ) ||
      userObject.superuser ||
      userObject.agencyMember.pocPermissions !== undefined
    )
  ) {

    $rootScope.token = userObject.authToken;
    $rootScope.refreshToken = userObject.refreshToken;
    $rootScope.loginType = userObject.loginType;
    $rootScope.userId = userObject.userId;
    if (userObject.agency) {
      $rootScope.agencyId = userObject.agency.id;
    }
    if (userObject.agencyMember) {

      // userObject = userObject.loginData.agency;
      // userObject = userObject.loginData.agencyMember;
      // userObject = userObject.loginData.coordinator;
      // userObject = userObject.loginData.type;

      $rootScope.agencyMemberId = userObject.agencyMember.id;
    }

    $rootScope.user = userObject;



    $rootScope.user.verified = true;
    if (userObject.agencyMember) {
      $rootScope.user.displayName = $rootScope.user.agencyMember.firstName + ' ' + $rootScope.user.agencyMember.lastName;
    }
    $rootScope.setCompanyType(userObject.organizationType);
    $rootScope.user.email = Storage.get('userEmail');

    if ($rootScope.user.superuser) {
      $rootScope.user.admin = true;
    } else if (userObject.agencyMember) {
      if (userObject.agencyMember.jobTitle === 'Admin') $rootScope.user.admin = true;
    }

    if ($rootScope.agencyId !== undefined && $rootScope.agencyMemberId !== undefined) {
      agencyMemberMentionsService.getInboxUnread().then((res) => {
        $rootScope.mentionesCount = res.data.unread.length;
      });
    }

    const agenciesToTrack = { 8614: "Rockaway" };

    if (
      import.meta.env.PROD &&
      $rootScope.agencyMemberId &&
      agenciesToTrack[$rootScope.agencyId] !== undefined
    ) {
      ls.init("d7a0aeb6.ae87ce34");
      ls.newPageView();
      ls.identify({ name: $rootScope.user.displayName, email: $rootScope.user.email })
    }

    Socket.init(userObject);
    DatabaseApi.connect();
  }

  // if($rootScope.agencyId !== 62) $rootScope.showSweepStakes = false;
  $rootScope.showSweepStakes = false;

  window.addEventListener('popstate', function() {
    setTimeout(() => {
      const caregiverParam = getParameterByName('caregiver');
      // Re-open the modal in case of pop state with query param
      if (caregiverParam) {
        $rootScope.handleOpenCaregiverModal(caregiverParam);
      } else {
        // Close the modal in case of pop state with no query param
        $rootScope.closeCaregiverModal();
      }

      const patientParam = getParameterByName('patient');
      // Re-open the modal in case of pop state with query param
      if (patientParam) {
        $rootScope.handleOpenPatientModal(patientParam);
      } else {
        // Close the modal in case of pop state with no query param
        $rootScope.closePatientModal();
      }

      const invoiceParam = getParameterByName('invoice');
      // Re-open the modal in case of pop state with query param
      if (invoiceParam) {
        $rootScope.handleOpenInvoiceModal({ invoiceId: invoiceParam });
      } else {
        // Close the modal in case of pop state with no query param
        $rootScope.closeInvoiceModal();
      }

      const visitInstanceParam = getParameterByName('visitInstance');
      // Re-open the modal in case of pop state with query param
      if (visitInstanceParam) {
        $rootScope.handleOpenVisitInstanceModal(visitInstanceParam, getParameterByName('visitInstancePatient') || null);
      } else {
        // Close the modal in case of pop state with no query param
        $rootScope.closeVisitInstanceModal();
      }
    }, 200);
  });

  $transitions.onStart({}, function(trans) {
    const toState = trans.$to();
    if ($rootScope.activeChat) $rootScope.closeChat();

    Analytics.pageView(toState.name);

    if ((!$rootScope.user || !$rootScope.user.email || !$rootScope.user.verified) && preLoginStates.indexOf(toState.name) === -1) {
      $state.go('login');
    } else if ($rootScope.user && $rootScope.user.email && preLoginStates.indexOf(toState.name) !== -1) {
      if (!$rootScope.stats) init();
      $state.go('app.dashboard');
    } else {
      if (!$rootScope.stats && $rootScope.user && $rootScope.user.email) init();
    }

    setTimeout(() => {
      // Open caregiver modal when URL conatins query param
      const caregiverParam = getParameterByName('caregiver');
      if (caregiverParam) {
        $rootScope.handleOpenCaregiverModal(caregiverParam);
      }

      // Open patient modal when URL conatins query param
      const patientParam = getParameterByName('patient');
      if (patientParam) {
        $rootScope.handleOpenPatientModal(patientParam);
      } else {
        // Close the modal in case of pop state with no query param
        $rootScope.closePatientModal();
      }

      const invoiceParam = getParameterByName('invoice');
      // Re-open the modal in case of pop state with query param
      if (invoiceParam) {
        $rootScope.handleOpenInvoiceModal({ invoiceId: invoiceParam });
      } else {
        // Close the modal in case of pop state with no query param
        $rootScope.closeInvoiceModal();
      }

      const visitInstanceParam = getParameterByName('visitInstance');
      // Re-open the modal in case of pop state with query param
      if (visitInstanceParam) {
        $rootScope.handleOpenVisitInstanceModal(visitInstanceParam, getParameterByName('visitInstancePatient') || null);
      } else {
        // Close the modal in case of pop state with no query param
        $rootScope.closeVisitInstanceModal();
      }
    }, 200);
  });

  $transitions.onError({}, function(trans) {
    console.error(trans.error());
  });

  $rootScope.$state = $state;


  $rootScope.isHr = function() {
    if (!$rootScope.user || !$rootScope.user.agencyMember || !$rootScope.user.agencyMember.jobTitle || $rootScope.user.agencyMember.jobTitle === 'Admin') {
      if (!$rootScope.user || !$rootScope.user.agencyMember) return false;
      if ($rootScope.user.agencyMember.jobTitle === 'Admin') return false;
      if ($rootScope.user.agencyMember && $rootScope.user.agencyMember.jobTitle === 'HR') return true;
    } else if ($rootScope.user.agencyMember.jobTitle === 'HR') return true;
    return false;
  };

  $rootScope.showViewByUserRole = function(view) {
    //if(role === 'Admin') return true;

    var role;

    if (!$rootScope.user) return false;
    if ($rootScope.user.superuser) return true;

    if (!$rootScope.user.agencyMember || !$rootScope.user.agencyMember.jobTitle || $rootScope.user.agencyMember.jobTitle === 'Admin') {
      if ($rootScope.user.agencyMember && $rootScope.user.agencyMember.jobTitle === 'Admin') return true;
    } else {
      role = $rootScope.user.agencyMember ? $rootScope.user.agencyMember.jobTitle : false;
    }


    if (role === 'HR') {
      if (view === 'visits') return false;
      if (view === 'confirmation') return false;
      if (view === 'payroll') return false;
      if (view === 'reports') return false;
      if (view === 'export-filters') return false;
      return true;

    } else if (role === 'Coordinator' || role === 'SeniorCoordinator') {
      if (view === 'payroll') return false;
      if (view === 'downloadBar') return false;
      if (view === 'reports') return false;
      //if (view === 'doc-expirations') return false;
      if (view === 'hrSettings') return false;
      if (view === 'notesSettings') return false;
      if (view === 'hrPendingApplication') return false;
      if (view === 'pending') return role === 'SeniorCoordinator';
      if (view === 'export-filters') return false;
      if (view === 'recruitment') return false;

      return true;
    }

    return false;
  };


  function init() {

    $rootScope.pendingCaregivers = DatabaseApi.getPendingCaregivers();
    //console.log($rootScope.pendingCaregivers);
    $rootScope.stats = {
      pending: 0,
      changes: 0,
      newCareGivers: 0,
      paymentsWaiting: 0,
      hrPapers: 0,
      scheduled: 0,
      waiting: 0,
      confirmed: 0,
      completed: 0,
      canceled: 0
    };

    DatabaseApi.chats();
    DatabaseApi.caregivers();

    $rootScope.caregiversPendingApplication = DatabaseApi.getCaregiversPendingApplication();
  }

  function getCaregiverById(id) {
    /*var givers = DatabaseApi.caregivers();
    if (!givers) return;

    var flag;
    givers.forEach(function (g) {
      if (g.id === id) flag = g;
    });
    return flag;
    */
    //console.log(id);
    var c = caregiversMap[id.toString()];
    //console.log(c);
    if (c) return c;
    if ($rootScope.pendingCaregivers) {
      var flag;
      $rootScope.pendingCaregivers.forEach(function(g) {
        if (g.id === id) flag = g;
      });
      return flag;
    }
    return false;
  }

  var notificationsActive = false;

  function preSetVisits(push, caregiversRefresh) {

    if (!$rootScope.stats) return;

    if ($rootScope.activeChat && $rootScope.activeChat.caregiver)
      $rootScope.activeChat.caregiverObject = getCaregiverById($rootScope.activeChat.caregiver);

    setVisits(caregiversRefresh);

    if (push) {
      $timeout(function() {
        notificationsActive = false;
      }, 5000);

      if (!notificationsActive) {
        ////console.log($rootScope.stats);
        if ($rootScope.stats.waiting || $rootScope.stats.changes) {
          notificationsActive = true;
          //Notifications.desktop('New notification are waiting for you', '');
        }

      }
    }
  }

  function presetCaregivers() {
    caregiversMap = DatabaseApi.caregivers();
  }

  $rootScope.$on('got_data', function(event) {
    //console.log('got all data');
    init();
    presetCaregivers();
    preSetVisits(true, 'caregivers');
  });

  $rootScope.$on('visit_changed', function(event) {
    preSetVisits(true);
  });

  $rootScope.$on('new_visit', function(event) {
    preSetVisits();
  });

  $rootScope.$on('caregiver_changed', function(event) {
    presetCaregivers();
    $rootScope.pendingCaregivers = DatabaseApi.getPendingCaregivers();
    preSetVisits(null, 'caregivers');
  });
  $rootScope.$on('new_caregiver', function(event) {
    presetCaregivers();
    preSetVisits(null, 'caregivers');
  });

  function getLastViewed(chat) {
    var last = undefined;
    chat.members.forEach(function(member) {
      if (member.user.type === 'AgencyMember') {
        ////console.log(member.user.agencyMemberId);
        var l = member.lastViewed;
        if (!last || last < l) last = l;
      }
    });
    ////console.log(last);
    return last;
  }

  function setVisits(caregiversRefresh) {

    $rootScope.notifications = [];

    var pending = 0;
    var changes = 0;
    var scheduled = 0;
    var completed = 0;
    var confirmed = 0;
    var waiting = 0;
    var cancelled = 0;
    var unstaffed = 0;

    var chats = DatabaseApi.chats();
    var visits;
    const visitsResult = DatabaseApi.visits();
    if (visitsResult.broadcasts && visitsResult.unstaffed) {
      visits = visitsResult.broadcasts;
      unstaffed += visitsResult.unstaffed.length;
    } else {
      DatabaseApi.getVisits().then(db => {
        visits = db.visits.broadcasts;
        unstaffed += db.visits.unstaffed.length;
      });
    }
    var chatMap = {};
    chats.forEach(function(c) {

      if (!c.chatRoomType.visitId) return;
      //var last = getLastViewed(c);
      //var last = new Date(c.chat.agencyLastViewed).getTime();

      ////console.log(last);
      //if(!last || last < now){
      var visitId = c.chatRoomType.visitId.toString();
      if (!chatMap[visitId]) chatMap[visitId] = []
      chatMap[visitId].push(c.chatRoomType.caregiverId);
      //}

    });
    //console.log(chatMap);

    var notHR = !$rootScope.isHr();

    angular.forEach(visits, function(record) {

      record.status = VisitStatus.getVisitStatus(record);

      if (record.fullTimeOffered) {
        var c = DatabaseApi.getPro(record.fullTimeOffered);
        if (c) {
          var text = c.firstName + ' ' + c.lastName + ' (pending)';
          record.assignedCaregiverPending = text;
        }
      }

      if (record.awaitingCaregiverPatientContact) {
        var c = DatabaseApi.getPro(record.awaitingCaregiverPatientContact);
        if (c) {
          var text = c.firstName + ' ' + c.lastName + ' (pending)';
          record.awaitingCaregiverPatientContactName = text;
        }
      }


      if (notHR) {
        if (record.status === 'unstaffed') unstaffed++;
        else if (record.status === 'pending') pending++;
        else if (record.status === 'scheduled') scheduled++;
        else if (record.status === 'waiting') {
          $rootScope.notifications.push({
            type: 'waiting',
            data: record,
            time: new Date(record.updatedAt).getTime()
          });
          waiting++;
        }
      }

      /*
       if(record.newChatMessages) {
       $rootScope.notifications.push({type: 'message', data: record});
       $rootScope.totalNewChats += record.newChatMessages;
       }

       if(chatMap[record.id.toString()]){

       ////console.log('has chat');
       ////console.log(chatMap[record.id.toString()]);
       var c = chatMap[record.id.toString()];

       c.forEach(function(data){
       //console.log(data);
       var caregiver = DatabaseApi.getPro(data);
       if(!caregiver) return;
       var notification = {
       id: record.id,
       visitId: record.id,
       caregiverId: data,
       name: record.patientName,
       caregiverName: caregiver.displayName,
       type: 'message'
       }
       $rootScope.notifications.unshift(notification);
       });

       } */

    });

    var getNameFromVisitId = function(id) {
      var flag;
      visits.forEach(function(visit) {
        if (visit.id === id) flag = visit.patientName;
      });
      return flag;
    };

    chats.forEach(function(c) {

      if (!c.messages) return;
      var last = getLastViewed(c);
      //var last = new Date(c.chat.agencyLastViewed).getTime();
      /*
       //console.log('+++');
       //console.log(last);
       //console.log(new Date(c.messages[c.messages.length-1].createdAt).getTime());
       //console.log(last < new Date(c.messages[c.messages.length-1].createdAt).getTime());
       //console.log('+++');
       */
      if (c.messages.length && (!last || last < c.messages[c.messages.length - 1].createdAt)) {
        if (c.chatRoomType.type === 'AgencyMemberCaregiverChat') {
          var caregiver = DatabaseApi.getPro(c.chatRoomType.caregiverId);
          if (!caregiver) return; //console.log('+++ err caregiver a');
          c.messages.forEach(function(message) {

            if ($state.current.name === 'app.communication' && $rootScope.activeAgencyChat === c.id) return;
            if ((!last || message.createdAt > last) && message.user.type === 'Caregiver') {

              var notification = {
                id: c.id,
                caregiverId: c.chatRoomType.caregiverId,
                caregiverName: caregiver.displayName,
                type: 'agencyMessage',
                time: new Date(message.createdAt).getTime()

              };
              $rootScope.notifications.unshift(notification);
            }
          });

        } else if (c.chatRoomType.type === "CaregiverPatientChat") {
          const caregiver = DatabaseApi.getPro(c.chatRoomType.caregiverId);

          if (!caregiver) {
            return;
          }

          for (const message of c.messages) {
            // There's no need to send a notification to the person who's already
            // in the conversation.
            if (
              $rootScope.activeChatPatient &&
              $rootScope.activeChatPatient.id === c.id
            ) {
              return;
            }

            const isNewMessage = !last || message.createdAt > last;
            const isMessageFromCaregiver = message.user.type === 'Caregiver';

            if (isNewMessage && isMessageFromCaregiver) {
              const notification = {
                id: c.id,
                caregiverId: c.chatRoomType.caregiverId,
                caregiverName: caregiver.displayName,
                patientName: c.chatRoomType.patientName,
                patientId: c.chatRoomType.patientId,
                type: 'patientMessage',
                time: new Date(message.createdAt).getTime()

              };
              $rootScope.notifications.unshift(notification);
            }
          }
        } else {
          if (notHR) {
            var caregiver = DatabaseApi.getPro(c.chatRoomType.caregiverId);
            //if(!caregiver) return //console.log('+++ err caregiver v');
            //if(c.id === 115) //console.log('+=+= ', c);
            if (!caregiver) return;
            c.messages.forEach(function(message) {

              if ((!last || message.createdAt > last) && message.user.type === 'Caregiver') {


                var notification = {
                  id: c.id,
                  visitId: c.chatRoomType.visitId,
                  caregiverId: c.chatRoomType.caregiverId,
                  name: getNameFromVisitId(c.chatRoomType.visitId),
                  caregiverName: caregiver.displayName,
                  type: 'message',
                  time: new Date(message.createdAt).getTime()
                };
                if (notification.name) {
                  $rootScope.notifications.unshift(notification);
                }
              }
            });
          }
        }


      } else {
        ////console.log('+++not');
        ////console.log(c);

      }

    });

    $rootScope.stats.pending = pending;
    $rootScope.stats.scheduled = scheduled;
    $rootScope.stats.waiting = waiting;
    $rootScope.stats.changes = changes;
    $rootScope.stats.confirmed = confirmed;
    $rootScope.stats.completed = completed;
    $rootScope.stats.cancelled = cancelled;
    $rootScope.stats.unstaffed = unstaffed;

    if (DatabaseApi.caregivers() && caregiversRefresh) setCaregivers();

    $rootScope.updateMentionsNotifications();
  }

  $rootScope.updateMentionsNotifications = function() {
    agencyMemberMentionsService.getInboxUnread().then((res) => {
      const mentions = res.data.unread || [];

      const mentionsNotifications = mentions.map(mention => {
        const { mentioningAgencyMemberName, noteId, mentionId } = mention;
        return {
          mentionId,
          noteId,
          mentioningAgencyMemberName,
          type: "noteMention",
          time: new Date(mention.createdAt).getTime()
        };
      });

      $rootScope.notifications = [
        ...$rootScope.notifications.filter(({ type }) => type !== "noteMention"),
        ...mentionsNotifications
      ];
    });
  }

  function setCaregivers() {

    const caregivers = DatabaseApi.caregivers();

    var statsCaregivers = {
      count: caregivers.length,
      active: 0,
      pending: 0,
      on_hold: 0,
      on_leave: 0,
      suspended: 0,
      terminated: 0,
      installed: 0,
      uninstalled: 0,
      activeInstalled: 0,
      pendingInstalled: 0,
      on_holdInstalled: 0,
      on_leaveInstalled: 0,
      suspendedInstalled: 0,
      terminatedInstalled: 0,
      activeUninstalled: 0,
      pendingUninstalled: 0,
      on_holdUninstalled: 0,
      on_leaveUninstalled: 0,
      suspendedUninstalled: 0,
      terminatedUninstalled: 0,
      ids: {
        active: [],
        pending: [],
        on_hold: [],
        on_leave: [],
        suspended: [],
        terminated: [],
        join_request: [],
        installed: [],
        unInstalled: [],
        notInstalled: [],
        activeInstalled: [],
        activeUnInstalled: [],
        activeNotInstalled: []
      }
    };

    var newCareGivers = 0;
    var hrDocs = 0;
    var oneDay = new Date().getTime() + 86400000;

    var haveFullTimeRns = false;

    for (var key in caregivers) {
      var record = caregivers[key];

      if (record.isFullTime) haveFullTimeRns = true;
      /*
      if (record.appInstalledDate && oneDay - new Date(record.appInstalledDate).getTime() > 0) {
        $rootScope.notifications.push({type: 'newCaregiver', data: record, time: new Date(record.appInstalledDate).getTime()});
        newCareGivers++;
      }
      */

      // status

      try {
        statsCaregivers[record.status.toLowerCase()]++;
        statsCaregivers.ids[record.status.toLowerCase()].push(record.id);
      } catch (e) {
        Raven.captureMessage('status does not exist: ' + record.status.toLowerCase());
      }


      if (record.appInstalled) {
        statsCaregivers[record.status.toLowerCase() + 'Installed']++;
        statsCaregivers.ids.installed.push(record.id);
        statsCaregivers.installed++;
        if (record.status.toLowerCase() === 'active') statsCaregivers.ids.activeInstalled.push(record.id);

      } else if (record.appInstalledDate) {
        statsCaregivers[record.status.toLowerCase() + 'Uninstalled']++;
        statsCaregivers.ids.unInstalled.push(record.id);
        statsCaregivers.uninstalled++;
        if (record.status.toLowerCase() === 'active') statsCaregivers.ids.activeUnInstalled.push(record.id);

      } else if (record.status.toLowerCase() === 'active') {
        statsCaregivers.ids.notInstalled.push(record.id);
        if (record.status.toLowerCase() === 'active') statsCaregivers.ids.activeNotInstalled.push(record.id);
      }

      //if (record.hrDocs < 100) hrDocs++;
    }

    $rootScope.haveFullTimeRns = haveFullTimeRns;

    $rootScope.stats.newCareGivers = newCareGivers;
    $rootScope.stats.hrPapers = hrDocs;


    $rootScope.stats.caregivers = statsCaregivers;
    $rootScope.stats.dataReady = true;
  }

  $rootScope.getCalendarFirstDayOfWeek = () => {
    const value =
      typeof $rootScope.visitSettings.calendarStartDayOfTheWeek === "string"
        ? parseInt($rootScope.visitSettings.calendarStartDayOfTheWeek, 10)
        : $rootScope.visitSettings.calendarStartDayOfTheWeek;

    return {
      0: DayOfWeek.SUNDAY,
      1: DayOfWeek.MONDAY,
      2: DayOfWeek.TUESDAY,
      3: DayOfWeek.WEDNESDAY,
      4: DayOfWeek.THURSDAY,
      5: DayOfWeek.FRIDAY,
      6: DayOfWeek.SATURDAY,
    }[value];
  }


  function getTimeoutTime() {
    var time = $rootScope.visitSettings ? $rootScope.visitSettings.logOutTime : 10;
    return time ? time * 60000 : 60 * 60 * 24 * 1000;
  }

  var props = [{
      name: 'activeAgencyChat',
      type: 'b'
    },
    {
      name: 'activeChat',
      type: 'b'
    },
    {
      name: 'activeChatId',
      type: 'b'
    },
    {
      name: 'activeChatList',
      type: 'a'
    },
    {
      name: 'activeChatPatient',
      type: 'b'
    },
    {
      name: 'activeChatPatientVisit',
      type: 'b'
    },
    {
      name: 'activeIdx',
      type: 'b'
    },
    {
      name: 'activeModalId',
      type: 'b'
    },
    {
      name: 'agencyId',
      type: 'b'
    },
    {
      name: 'agencyId',
      type: 'b'
    },
    {
      name: 'agencyMemberId',
      type: 'b'
    },
    {
      name: 'caregiverChatId',
      type: 'b'
    },
    {
      name: 'visitDetails',
      type: 'o'
    },
    {
      name: 'notifications',
      type: 'a'
    },
    {
      name: 'totalNewChats',
      type: 'b'
    },
    {
      name: 'pendingCaregivers',
      type: 'a'
    },
    {
      name: 'pendingVisitEditRequests',
      type: 'a'
    },
    {
      name: 'commPageChatCounterCache',
      type: 'o'
    },
    {
      name: 'coordinatorRefferalCode',
      type: 'b'
    },
    //{name: 'token', type: 'b'},
    {
      name: 'userId',
      type: 'b'
    },
    {
      name: 'sweepCounter',
      type: 'b'
    },
    {
      name: 'sweepScore',
      type: 'b'
    },
    {
      name: 'internalChatCounter',
      type: 'b'
    },
    {
      name: 'stats',
      type: 'o'
    }
  ];

  $rootScope.idleLogout = function() {
    var t;
    window.onload = resetTimer;
    window.onmousemove = resetTimer;
    window.onmousedown = resetTimer;
    window.onkeydown = resetTimer;
    window.onwheel = resetTimer;
    window.ontouchstart = resetTimer;
    window.ontouchmove = resetTimer;
    window.onclick = resetTimer;
    window.onscroll = resetTimer;

    function logout() {
      $rootScope.user = {};
      $rootScope.refreshToken = undefined;
      props.forEach(function(p) {
        if (p.type === 'a') $rootScope[p.name] = [];
        if (p.type === 'b') $rootScope[p.name] = false;
        if (p.type === 'o') $rootScope[p.name] = {};
      });
      Storage.softClear();
      $window.location.reload();
      // $state.go('login');
    }

    function resetTimer() {
      clearTimeout(t);
      if (!$rootScope.user) return;
      t = setTimeout(logout, getTimeoutTime());
    }
  }
  $rootScope.idleLogout();


  /////////
  ///hubspot widget
  /////////
  // if (window.HubSpotConversations) {
  //   window.hsConversationsSettings = {
  //     loadImmediately: false, // Allows you to manually load the widget using .load()
  //   };
  //   window.HubSpotConversations.widget.load({ widgetOpen: false });
  // } else {
  //   window.hsConversationsOnReady = [
  //     () => {
  //       window.hsConversationsSettings = {
  //         loadImmediately: false, // Allows you to manually load the widget using .load()
  //       };
  //       window.HubSpotConversations.widget.load({ widgetOpen: false });
  //     },
  //   ];
  // }



  /////////
  ///chats
  /////////

  $rootScope.openAgencyChat = function(caregiverId) {
    if (!caregiverId) return;

    DatabaseApi.get('chats/agency_member_caregiver_chat_rooms/' + $rootScope.agencyMemberId + '/' + caregiverId).then(function(res) {
      var chat = res.data;
      //console.log(chat);

      //console.log('chat change');


      chat.agencyChat = true;

      if ((!$rootScope.stealthMode || chat.chatRoomType.agencyMemberId === $rootScope.agencyMemberId) && chat && chat.messages && chat.messages.length) {
        var last = chat.messages[chat.messages.length - 1].createdAt;
        DatabaseApi.put('chats/' + chat.id + '/viewed', {
          messageTime: last
        }).then(function(res) {});
      }

      $rootScope.activeAgencyChat = chat.id;
      $rootScope.openChat(chat, true);

    });
  };
  //  setTimeout(function(){
  //
  //  $rootScope.openAgencyChat(2);
  //  }, 2000);



  // OMER Asked to remove this on DEV-1570 in JIRA
  // $rootScope.openInternalChat = function () {

  //     var chat = DatabaseApi.getInternalChat();
  //     chat.isInternal = true;

  //     if (!chat.messages) return;

  //     $rootScope.internalChatCounter = 0;

  //     $rootScope.activeChatId = chat.id;

  //     $rootScope.activeChat = chat;

  //     if (chat.messages.length) {
  //         var last = $rootScope.activeChat.messages[$rootScope.activeChat.messages.length - 1].createdAt;

  //         DatabaseApi.put('chats/' + chat.id + '/viewed', { messageTime: last }).then(function (res) {
  //             if (!$rootScope.activeChat.members) $rootScope.activeChat.members = [];
  //             $rootScope.activeChat.members.forEach(function (member, i) {
  //                 if (member.user.agencyMemberId === $rootScope.agencyMemberId) {
  //                     $rootScope.activeChat.members[i].lastViewed = last;
  //                 }
  //             });

  //             DatabaseApi.setInternalChat($rootScope.activeChat);
  //         });
  //     } else {
  //         DatabaseApi.setInternalChat($rootScope.activeChat);
  //     }

  //     //console.log($rootScope.activeChat);
  //     $rootScope.chatForm = { text: '' };

  //     $rootScope.isChatOpen = false;
  //     $timeout(function () {
  //         $rootScope.isChatOpen = true;
  //     }, 1);

  // };


  $rootScope.openChat = function(chatRow, gotMessages) {


    var chatRowNew = angular.copy(chatRow);

    if (!chatRowNew.agencyChat) {
      $rootScope.activeAgencyChat = false;
    }

    $rootScope.chatForm = {
      text: ''
    };
    $rootScope.activeChat = null;

    if (gotMessages) {
      startRoom(chatRowNew);

    } else if (chatRowNew.id && chatRowNew.id !== -1 && !chatRowNew.fromList) {

      DatabaseApi.getMessagesByIds(chatRowNew.id).then(
        function(res) {

          startRoom(chatRow, res);


        },
        function(err) {
          //console.log(err);
        }
      );


    } else {
      var flag = false;
      if (!chatRowNew.chatRoomType && chatRowNew.caregiverId && chatRowNew.patientId) {
        flag = true;
        chatRowNew.chatRoomType = {
          caregiverId: chatRowNew.caregiverId,
          patientId: chatRowNew.patientId,
        }
      } else if (chatRowNew.fromList) {
        flag = true;
      }

      // Chat click via visit
      const currentVisit = $rootScope.activeChatPatientVisit;
      let url;
      if (currentVisit) {
        url = wildcard(
          "chats/caregiver/:caregiverId/patient/:patientId/visit/:visitId",
          chatRowNew.chatRoomType.caregiverId,
          chatRowNew.chatRoomType.patientId,
          currentVisit.id
        );
      } else {
        url = wildcard(
          "chats/caregiver/:caregiverId/patient/:patientId",
          chatRowNew.chatRoomType.caregiverId,
          chatRowNew.chatRoomType.patientId
        );
      }

      DatabaseApi.get(url).then(function(res) {

        if (flag) chatRowNew = res.data;

        chatRowNew.id = res.data.id;

        //$rootScope.openChat(chatRowNew);
        startRoom(chatRowNew);

      }, function(err) {

      });
    }
  };

  function startRoom(chatRow, res) {
    $rootScope.activeChatId = chatRow.id;

    if (chatRow.agencyChat) {
      $rootScope.activeChatPatient = undefined;
    } else {
      var patientId = chatRow.chatRoomType ? chatRow.chatRoomType.patientId : chatRow.patientId;
      $rootScope.activeChatPatient = DatabaseApi.getPatientById(patientId);
    }

    $rootScope.activeChat = res || chatRow;

    try {
      if (res && chatRow) chatRow = Object.assign(chatRow, res);

    } catch (e) {
      toaster.pop('error', 'Browser Not Supported', 'Please use chrome browser');

    }

    try {
      $rootScope.activeChat.caregiver = chatRow.chatRoomType ? chatRow.chatRoomType.caregiverId : chatRow.caregiverId;

      var caregiverObj = getCaregiverById(chatRow.chatRoomType.caregiverId);
      if (!caregiverObj) {
        counterRetries++;

        // console.log(counterRetries);
        $timeout(function() {
          startRoom(chatRow, res);
        }, 100 * counterRetries);

        return;
      }
      $rootScope.activeChat.caregiverName = chatRow.displayName || caregiverObj.displayName;
      $rootScope.activeChat.caregiverObject = getCaregiverById($rootScope.activeChat.caregiver);
    } catch (e) {
      console.log(e);
      toaster.pop('warning', 'Something went wrong', 'Please reload/refresh page');
    }
    var viewed;
    if ($rootScope.activeChat.members) {
      $rootScope.activeChat.members.forEach(function(member) {

        if (member.user.type === "Caregiver") {
          viewed = member.lastViewed;
        }

      });
    }
    $rootScope.activeChat.viewed = viewed;

    if ((!$rootScope.stealthMode || $rootScope.activeChat.chatRoomType.agencyMemberId === $rootScope.agencyMemberId) && $rootScope.activeChat.messages.length && chatRow.id) {
      var last = $rootScope.activeChat.messages[$rootScope.activeChat.messages.length - 1].createdAt;
      DatabaseApi.put('chats/' + $rootScope.activeChatId + '/viewed', {
        messageTime: last
      }).then(function(res) {
        if (!$rootScope.activeChat.members) $rootScope.activeChat.members = [];
        $rootScope.activeChat.members.forEach(function(member, i) {
          if (member.user.type === 'AgencyMember') {
            $rootScope.activeChat.members[i].lastViewed = last;
          }
        });
        //console.log($rootScope.activeChat);
        if (!chatRow.agencyChat) DatabaseApi.setChat($rootScope.activeChat);
        //setVisits();
        $rootScope.$broadcast('visit_changed');
      });
    }
    //console.log($rootScope.activeChat);
    //console.log('start room');
    $rootScope.chatForm.text = '';

    $rootScope.isChatOpen = false;
    $timeout(function() {
      $rootScope.isChatOpen = true;
    }, 1);
  }

  $rootScope.closeChat = function() {
    $timeout(function() {
      $rootScope.isChatOpen = false;
    }, 1);
    $rootScope.activeChatId = false;
    $rootScope.activeChatPatient = false;
    $rootScope.activeChatPatientVisit = false;
    $rootScope.activeChat = null;
    $rootScope.activeAgencyChat = false;
  };

  $rootScope.removeChatBoxElementFromDOM = function() {
    const chatWrapperElement = document.getElementById("comm-center-chat-window");

    if(chatWrapperElement !== null) {
      chatWrapperElement.parentElement.remove();
    }
  }

  $rootScope.assignCaregiverToPatientChat = function(visit, patient, caregiver, cb) {
    if (caregiver.status !== "ACTIVE") {
      toaster.pop("warning", "Unable to perform action", "Caregiver is not active");
    }

    if (!visit) {
      $uibModal.open({
        templateUrl: "admin/views/pick-visit-modal.html",
        size: "lg",
        controller: "pickVisitModalCtrl",
        resolve: {
          caregiver: () => caregiver,
          patientId: () => patient !== undefined ? patient.id : undefined
        }
      })
      return;
    }

    if (visit.status === "scheduled") {
      SweetAlert.swal({
        title: "The case has already been assigned!",
        type: "error",
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "OK",
        closeOnConfirm: true
      });
      return;
    }

    if (visit.flexibleVisitParams || visit.rnVisitParams) {
      toaster.pop(
        "warning",
        "Cannot assign - action required",
        "Please ask the caregiver to selected the preferred days and/or time"
      );
      return;
    }

    let caregiverId;

    if (caregiver) {
      caregiverId = caregiver.caregiver ? caregiver.caregiver : caregiver.id;
    } else {
      return toaster.pop("error", "Something went wrong");
    }

    if (visit.isSingleVisit) {
      const url = wildcard(
        "agencies/:agencyId/agency_members/:agencyMemberId/visits/:visitId/assigned_to",
        $rootScope.agencyId,
        $rootScope.agencyMemberId,
        visit.id
      );

      const body = {
        caregiverId: caregiverId,
        partialAssignDetails: null,
        flexibleVisitRequestParams: null
      }

      DatabaseApi.put(url, body)
        .then((res) => {
          if (res.data.assignedWithOvertime) {
              toaster.pop({
                  type: 'warning',
                  title: 'Warning',
                  body: `Caregiver was assigned with increased caregiver overtime`
              });
          } else {
            toaster.pop("success", "Success", "Caregiver has assigned to visit");
          }

          Analytics.event("visit-chat-assigned", {
            visitId: visit.id,
            status: "scheduled",
            newCaregiver: caregiverId,
            accepted: visit.acceptedBy.length
          });

          if (cb) {
            cb();
          }
        })
        .catch((err) => {
          if (err.data && err.data.error === "Not permitted to increase caregiver overtime") {
            toaster.pop('error', 'Error', err.data.error);
          } else {
            toaster.pop("error", "Error", "Failed to assign visit.");
          }
          console.error(err);
        })
    } else {
      const modalInstance = $uibModal.open({
        templateUrl: 'admin/views/assign-modal.html',
        size: 'md',
        controller: 'assignModalCtrl',
        windowClass: 'top-top',
        resolve: {
          visit: () => visit,
          caregiver: () => caregiver
        }
      });

      modalInstance.result.then(() => {
        if (cb) {
          cb();
        }
      });
    }
  }

  $rootScope.assignCaregiverToVisitChat = function(visit, caregiver, cb) {
    //console.log(visit);
    //console.log(caregiver);
    let patientId;
    if (caregiver.chat) {
      patientId = caregiver.chat.chatRoomType.patientId;
    }

    if (caregiver.status !== "ACTIVE") {
      toaster.pop("warning", "Unable to preform action", "Caregiver is not active");
      return;
    }
    if (!visit) {
      //console.log('agencyChat');
      var modalInstance = $uibModal.open({
        templateUrl: 'admin/views/pick-visit-modal.html',
        size: 'lg',
        controller: 'pickVisitModalCtrl',
        resolve: {
          caregiver: function() {
            return caregiver;
          },
          patientId: function() {
            return patientId;
          }
        }

      });
      modalInstance.result.then(function(res) {
        console.log('modal out');
        //console.log(res);
      }, function() {
        console.log('modal close');
      });

    } else if (visit.status === 'scheduled') {

      SweetAlert.swal({
        title: "The case has already been assigned!",
        text: "",
        type: "error",
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "OK",
        closeOnConfirm: true
      });

    } else if (visit.flexibleVisitParams || visit.rnVisitParams) {

      toaster.pop('warning', "Cannot assign - action required", 'Please ask caregiver to select preferred days and/or hours');

    } else {

      if (caregiver) {
        var caregiverId = caregiver.caregiver ? caregiver.caregiver : caregiver.id;
      } else {
        return toaster.pop('error', "Something went wrong");
      }

      if (visit.isSingleVisit) {


        //console.log(caregiver);
        var url = "agencies/" + $rootScope.agencyId + "/agency_members/" + $rootScope.agencyMemberId + "/visits/" + visit.id + "/assigned_to";
        DatabaseApi.put(url, {
          caregiverId: caregiverId,
          partialAssignDetails: null,
          flexibleVisitRequestParams: null
        }).then(function(res) {
          if (res.data.assignedWithOvertime) {
              toaster.pop({
                  type: 'warning',
                  title: 'Warning',
                  body: `Caregiver was assigned with increased caregiver overtime`
              });
          } else {
            toaster.pop("success", "Success", "Caregiver has assigned to visit");
          }

          Analytics.event('visit-chat-assigned', {
            visitId: visit.id,
            status: 'scheduled',
            newCaregiver: caregiverId,
            accepted: visit.acceptedBy.length
          });


          //$rootScope.sweepScore.points += 100;

          if (cb) cb();

        }, function(err) {

          var errText = '';
          if (err.data) errText = err.data.error ? err.data.error : '';

          if (err.status === 409) toaster.pop('error', "Assigning Conflict", errText);
          else {

            toaster.pop('error', "Something went wrong", errText);
          }
        });

      } else {

        var modalInstance = $uibModal.open({
          templateUrl: 'admin/views/assign-modal.html',
          size: 'md',
          controller: 'assignModalCtrl',
          windowClass: 'top-top',
          resolve: {
            visit: function() {
              return visit;
            },
            caregiver: function() {
              return caregiver;
            }
          }

        });
        modalInstance.result.then(function(res) {
          //console.log('modal click');
          //console.log(res);
          if (cb) cb();

        }, function() {
          //console.log('modal close');
        });

      }



    }

  };

  $rootScope.closeSweepStakesFlag = {
    state: false
  }
  $rootScope.closeSweepStakes = function(forever) {
    $rootScope.showSweepStakes = false;
    if ($rootScope.closeSweepStakesFlag.state) Storage.set('hide-sweep-vegas', 'true');
    //console.log(forever);
  };

  $rootScope.goToSweepRules = function() {
    $window.open('https://s3-us-west-2.amazonaws.com/medflytfiles/Sweepstakes+Rules+and+Terms.pdf');
  };
  $rootScope.goToInviteCaregiver = function() {
    $state.go('app.addCaregiver');
  };

  $rootScope.newChatMessage = function(caregiverId, caregiverId2, visitId, message, chatId) {
    var caregiver = caregiverId ? caregiverId : caregiverId2;
    //console.log($rootScope.activeChat);
    DatabaseApi.postMessage(caregiver, visitId, message, chatId);
    //console.log('new chat message');
    $rootScope.chatForm.text = '';
  };

  /*

  $rootScope.$on('visit_chat_updated', function (event, data) {
    ////console.log(data.caregiverId);
    // //console.log(data.visitId);
    return;


  });
  */

  $rootScope.$on('sweep_score_updated', function(event) {
    DatabaseApi.connect('onlyScore');
  });

  $rootScope.$on('patient_profile_updated', function(event) {
    DatabaseApi.connect('onlyPatients');
  })


  $rootScope.$on('chat_room_updated', function(event, data) {
    //return;

    DatabaseApi.getMessagesByIds(data.id).then(function(res) {
      //$rootScope.$broadcast('visit_changed');
      res.data = res;
      //var res = {data: res};
      //console.log(res.data);




      if (res.data.chatRoomType.type === 'InternalAgencyChat') {
        var oldChat = DatabaseApi.getInternalChat();
        var agencyMembersMap = DatabaseApi.getAgencyMembers();
        var isNewMessage;
        if (oldChat && oldChat.messages) {
          isNewMessage = res.data.messages.length > oldChat.messages.length;
        } else {
          isNewMessage = true;
        }
        res.data.isInternal = true;
        if ($rootScope.activeChat && $rootScope.activeChatId === res.data.id) {

          res.data.messages.forEach(function(message) {
            message.name = agencyMembersMap[message.user.agencyMemberId.toString()];
          });

          $rootScope.activeChat.messages = res.data.messages;
          $rootScope.activeChat.members = res.data.members;
          //$rootScope.activeChat.viewed = res.data.viewed;
          $rootScope.internalChatCounter = 0;

          var myLastView;
          $rootScope.activeChat.members.forEach(function(member, i) {
            if (member.user.agencyMemberId === $rootScope.agencyMemberId) myLastView = member.lastViewed;
          });

          if (myLastView && $rootScope.activeChat.messages.length &&
            $rootScope.activeChat.messages[$rootScope.activeChat.messages.length - 1].createdAt > myLastView) {
            var last = $rootScope.activeChat.messages[$rootScope.activeChat.messages.length - 1].createdAt;
            DatabaseApi.put('chats/' + res.data.id + '/viewed', {
              messageTime: last
            }).then(function(res) {

              $rootScope.activeChat.members.forEach(function(member, i) {
                if (member.user.agencyMemberId === $rootScope.agencyMemberId) {
                  $rootScope.activeChat.members[i].lastViewed = last;
                }
              });

              DatabaseApi.setInternalChat($rootScope.activeChat);

            });
          }
        } else {
          if (res.data.messages.length && isNewMessage &&
            res.data.messages[res.data.messages.length - 1].user.agencyMemberId !== $rootScope.agencyMemberId) {
            //console.log('++ conf');
            $rootScope.internalChatCounter++;
            try {
              bell.play().then(function(value) {
                bell.play();
              });
              Notifications.desktop("New Internal Chat", 'There is a new chat message');
            } catch (e) {

            }
          }
          DatabaseApi.setInternalChat(res.data);
        }

        //console.log(res.data);

      } else if (res.data.chatRoomType.type === 'AgencyMemberCaregiverChat') {

        var viewed = undefined;
        res.data.members.forEach(function(member) {
          if (member.user.type === 'Caregiver') {
            viewed = member.lastViewed;
          }
        });
        res.data.viewed = viewed;

        //console.log('AgencyMemberCaregiverChat');
        if ($rootScope.activeChat && $rootScope.activeAgencyChat === res.data.id && !$rootScope.activeChat.isInternal) {
          //console.log('AgencyMemberCaregiverChat active');
          $rootScope.activeChat.agencyLastViewed = res.agencyLastViewed;
          $rootScope.activeChat.caregiverLastViewed = res.caregiverLastViewed;
          $rootScope.activeChat.messages = res.messages;
          $rootScope.activeChat.members = res.data.members;
          $rootScope.activeChat.viewed = res.data.viewed;
        } else if (res.data.messages && res.data.messages[res.data.messages.length - 1] && res.data.messages[res.data.messages.length - 1].user.type !== "AgencyMember") {
          var proObject = DatabaseApi.getPro(res.data.chatRoomType.caregiverId);
          if (proObject && !data.onlyView) {
            var lastViewByAgencyMember = getLastViewed(res.data);
            if (res.data.messages.length && (!lastViewByAgencyMember || lastViewByAgencyMember < res.data.messages[res.data.messages.length - 1].createdAt)) {
              var notification = {
                id: res.data.id,
                caregiverId: res.data.chatRoomType.caregiverId,
                caregiverName: proObject.displayName,
                type: 'agencyMessage'
              }
              $rootScope.notifications.unshift(notification);
              Notifications.desktop("New Agency Chat", 'There is a new chat message from ' + notification.caregiverName);
              try {
                bell.play().then(function(value) {
                  bell.play();
                });
              } catch (e) {

              }
            }
          }
        }

        ////console.log(res.data);
        //setVisits();

        if (res.data.members) {
          var lastViewed;
          res.data.members.forEach(function(member) {
            if (member.user.type === 'AgencyMember') {
              if (member.lastViewed > lastViewed) lastViewed = member.lastViewed;
              else if (!lastViewed) lastViewed = member.lastViewed;
            }
          });
        }

        var notViewedCounter = 0;
        if (res.data.messages) {

          if (lastViewed) {
            var messagesLength = res.data.messages.length - 1;
            for (var i = messagesLength; i >= 0; i--) {
              if (!res.data.messages[i].broadcast && res.data.messages[i].createdAt > lastViewed) notViewedCounter++;
              else break;
            }
          }
          res.data.notViewedCounter = notViewedCounter || 0;
          if (notViewedCounter) $rootScope.commPageChatCounterCache[res.data.id] = true;
          else delete $rootScope.commPageChatCounterCache[res.data.id];
        }

        $rootScope.$broadcast('chat_room_updated_com', {
          data: data,
          res: res
        });

      } else if (res.data.chatRoomType.type === 'CaregiverPatientChat') {
        var viewed;
        res.data.members.forEach(function(member) {
          if (member.user.type === "Caregiver") {
            viewed = member.lastViewed;
          }
        });
        res.data.viewed = viewed;

        if (
          !$rootScope.activeChat ||
          ($rootScope.activeChatPatient && $rootScope.activeChatPatient.id !== data.chatRoomType.patientId) ||
          $rootScope.activeChat.chatRoomType.caregiverId !== data.caregiverId) {

          const patientId = res.data.chatRoomType ? res.data.chatRoomType.patientId : data.patientId;
          var caregiverId = res.data.chatRoomType ? res.data.chatRoomType.caregiverId : data.caregiverId;
          const patient = DatabaseApi.getPatientById(patientId);
          var caregiver = DatabaseApi.getPro(caregiverId);
          const message = res.data.messages[res.data.messages.length - 1];
          if (!data.onlyView && caregiver && patient && message && message.user.type === 'Caregiver') {
            var notification = {
              id: res.data.id,
              patientId: patientId,
              caregiverId: caregiverId,
              name: patient.displayName,
              caregiverName: caregiver.displayName,
              type: 'message'
            }
            $rootScope.notifications.unshift(notification);
            Notifications.desktop("New Patient Chat", 'There is a new chat message from ' + notification.caregiverName + ' about ' + notification.name);
            try {
              bell.play().then(function(value) {
                bell.play();
              });
            } catch (e) {

            }
          }
        }
        if (
          $rootScope.activeChat &&
          $rootScope.activeChatPatient &&
          $rootScope.activeChat.chatRoomType.caregiverId === res.data.chatRoomType.caregiverId
        ) {
          $rootScope.activeChat.agencyLastViewed = res.agencyLastViewed;
          $rootScope.activeChat.caregiverLastViewed = res.caregiverLastViewed;
          $rootScope.activeChat.messages = res.messages;
          $rootScope.activeChat.members = res.data.members;
          $rootScope.activeChat.viewed = res.data.viewed;
        }
        //console.log('changed');
        //console.log($rootScope.activeChat);
        $rootScope.$broadcast('visit_changed');


      }


    }, function(err) {
      //console.log(err);
    });
    /*
     DatabaseApi.get('chats/' + data.chatRoomId).then(function(res){


     //console.log(res.data);
     //console.log(data);




     });*/
  });

  $rootScope.getCaregiverPageURL = function(id) {
    if (!id) return;
    return window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      `?caregiver=${id}`;
  }

  $rootScope.openNoteMention = function(noteId, mentionId) {
    $state.go(
      "app.notes.mentionsInbox",
      { noteId, mentionId },
      { reload: true }
    );
  };

  $rootScope.openCaregiverModal = function(id, caregiverData, forceModal, applicationLandingCertification) {

    if (!id) return;

    let pathStateWithQuery = $rootScope.getCaregiverPageURL(id);

    window.history.pushState({
      path: pathStateWithQuery
    }, "", pathStateWithQuery);

    // Since the pushState() above doesn't trigger $stateChangeStart nor popState events, we open the modal "manually"
    $rootScope.handleOpenCaregiverModal(id, caregiverData, forceModal, applicationLandingCertification);
  };

  $rootScope.openCaregiverNewTab = function(id) {
    if (!id) return;

    let pathStateWithQuery = $rootScope.getCaregiverPageURL(id);

    window.open(pathStateWithQuery, "_blank");
  }

  $rootScope.handleOpenCaregiverModal = function(id, caregiverData, forceModal, applicationLandingCertification) {
    if (
      $rootScope.currentOpenedPatientIdModal !== undefined
    ) {
      $rootScope.closePatientModal(true);
    }

    $rootScope.caregiverModalInstance = $uibModal.open({
      templateUrl: 'admin/views/professional-modal.html',
      size: 'lg',
      backdrop: true,
      controller: 'professionalCtrl',
      windowClass: "modal modal-slide-in-right caregiver-modal",
      resolve: {
        id: function() {
          return id;
        },
        pro: function() {
          return caregiverData;
        },
        forceModal: function() {
          return forceModal;
        },
        offices: function($rootScope, officesService) {
          if (!officesService.offices) {
            return officesService.getOffices();
          } else {
            return officesService.offices;
          }
        },
        applicationLandingCertification: function() {
          return applicationLandingCertification;
        }
      }

    });
    $rootScope.caregiverModalInstance.closed.then(function(res) {
      $rootScope.closeCaregiverModal();
    });
    $rootScope.caregiverModalInstance.result.finally(() => {
      $state.go(
        $state.current.name, {
          caregiver: null
        }, {
          reload: false
        }
      );
    });
  };

  $rootScope.getInvoicePageURL = function(id) {
    if (!id) return;
    return window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      `?invoice=${id}`;
  }

  $rootScope.getCheckPageURL = function(id) {
    return window.location.protocol +
      "//" +
      window.location.host +
      '/app/billing/checks/' +
      id;
  }

  $rootScope.openInvoiceModal = function({ invoiceId, forceModal }) {
    let pathStateWithQuery = $rootScope.getInvoicePageURL(invoiceId);
    window.history.pushState({ path: pathStateWithQuery }, "", pathStateWithQuery);
    $rootScope.handleOpenInvoiceModal({ invoiceId, forceModal });
  };

  $rootScope.openInvoiceNewTab = function(id) {
    if (!id) return;
    let pathStateWithQuery = $rootScope.getInvoicePageURL(id);
    window.open(pathStateWithQuery, "_blank");
  }

  $rootScope.openCheckNewTab = function(id) {
    if (!id) return;
    const pathStateWithQuery = $rootScope.getCheckPageURL(id);
    window.open(pathStateWithQuery, "_blank");
  }

  $rootScope.handleOpenInvoiceModal = function({ invoiceId, forceModal }) {
    $rootScope.invoiceModalInstance = $uibModal.open({
      templateUrl: 'admin/views/billing-invoice-details-main.html',
      size: 'lg',
      backdrop: true,
      controller: 'billingInvoiceDetailsCtrl',
      windowClass: "modal modal-slide-in-right invoice-modal",
      resolve: {
        invoice: () => ( { id: invoiceId })
      }
    });

    $rootScope.invoiceModalInstance.result
        .finally(() => $state.go($state.current.name, { invoice: null }, { reload: false }));
  };

  $rootScope.closeInvoiceModal = function() {
    if ($rootScope.invoiceModalInstance && $rootScope.invoiceModalInstance.opened.$$state.value) {
      $rootScope.invoiceModalInstance.close()
    }
  };

  $rootScope.getPayrollBatchPageURL = (id) => {
    if (!id) return;
    return window.location.protocol +
      "//" +
      window.location.host +
      '/app/payroll/payrolls/' +
      id;
  };

  $rootScope.openPayrollBatchNewTab = (id) => {
    if (!id) return;
    const pathStateWithQuery = $rootScope.getPayrollBatchPageURL(id);
    window.open(pathStateWithQuery, "_blank");
  };

  $rootScope.openVideosModal = function(caregiverWithVideos) {

    var modalInstance = $uibModal.open({
      templateUrl: 'admin/views/videos-modal.html',
      size: 'lg',
      controller: 'videosModalCtrl',
      resolve: {
        caregiverWithVideos: function() {
          return (caregiverWithVideos);
        }
      }
    });
  };

  $rootScope.openVideoSessionsModal = function(video) {

    var modalInstance = $uibModal.open({
      templateUrl: 'admin/views/video-sessions-modal.html',
      size: 'lg',
      controller: 'videoSessionsModalCtrl',
      windowClass: 'top-top-top',
      resolve: {
        video: function() {
          return (video);
        }
      }
    });
  };

  $rootScope.openCalendarNewVisitModal = function(data) {
    return ($uibModal.open({
      templateUrl: 'admin/views/calendar-new-visit-modal.html',
      size: 'md',
      controller: 'calendarNewVisitModalCtrl',
      windowClass: 'calendar-new-visit-modal',
      resolve: {
        data: function() {
          return (data);
        }
      }
    }).result);
  };

  $rootScope.openNewTextNoteModal = function(newScope) {

    return (
      $uibModal.open({
        templateUrl: 'admin/views/create-simple-note-modal.html',
        size: 'md',
        controller: 'createSimpleNoteModalCtrl',
        scope: newScope,
        windowClass: "modal modal-slide-in-right create-note-container"
      }).result
    );
  };

  $rootScope.openNewAdjustmentApprovalNoteModal = function(newScope) {
    newScope.skipSaving= true;
    newScope.okButton= 'Yes, save changes and post note';
    newScope.description= 'The visit instance is billed or paid. Please post a note in order to save changes.';
    newScope.title= 'Are you sure you want to save changes?';
    newScope.note = {
      ...newScope.note,
      status: "NONE",
      noteRichText: "",
      noteType: noteConsts.NoteTypes.ADJUSTMENT_APPROVAL,
    }

    return (
      $uibModal.open({
        templateUrl: 'admin/views/create-simple-note-modal.html',
        size: 'md',
        controller: 'createSimpleNoteModalCtrl',
        scope: newScope,
        windowClass: "modal create-note-container-center top-top-top"
      }).result
    );
  };

  $rootScope.openNewVisitInstanceNoteModal = function(newScope) {
    newScope.note = {
      ...newScope.note,
      status: "NONE",
      noteRichText: "",
      noteType: noteConsts.NoteTypes.VISIT_INSTANCE
    }

    return (
      $uibModal.open({
        templateUrl: 'admin/views/create-simple-note-modal.html',
        size: 'md',
        controller: 'createSimpleNoteModalCtrl',
        scope: newScope,
        windowClass: "modal modal-slide-in-right create-note-container"
      }).result
    );
  };

  $rootScope.openNewCalendarNoteModal = function(newScope) {
    newScope.note = {
      ...newScope.note,
      status: "NONE",
      noteRichText: "",
      noteType: noteConsts.NoteTypes.CALENDAR
    }

    return (
      $uibModal.open({
        templateUrl: 'admin/views/create-simple-note-modal.html',
        size: 'md',
        controller: 'createSimpleNoteModalCtrl',
        scope: newScope,
        windowClass: "modal modal-slide-in-right create-note-container"
      }).result
    );
  };

  $rootScope.openUploadDocumentPdfModal = function(data) {
    return ($uibModal.open({
      templateUrl: 'admin/views/upload-document-pdf-modal.html',
      size: 'md',
      controller: 'uploadDocumentPdfModalCtrl',
      windowClass: 'upload-document-pdf-modal',
      resolve: {
        data: function() {
          return (data);
        }
      }
    }).result);
  };

  $rootScope.getPatientPageURL = function(id) {
    if (!id) return;
    return window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      `?patient=${id}`;
  }

  $rootScope.openPatientModal = function(id, forceModal) {
    if (!id) return;

    let pathStateWithQuery = $rootScope.getPatientPageURL(id);

    window.history.pushState({
      path: pathStateWithQuery
    }, "", pathStateWithQuery);

    $rootScope.handleOpenPatientModal(id, forceModal);
  }

  $rootScope.openPatientPocHistoryModal = function(patient, forceModal) {
    if (!patient) return;

    $rootScope.handleOpenPatientPocHistoryModal(patient, forceModal);
  }

  $rootScope.handleOpenPatientPocHistoryModal = function(patient) {
    $rootScope.patientPocHistoryModalInstance = $uibModal.open({
      templateUrl: 'admin/views/patient-poc-history-modal.html',
      size: 'lg',
      backdrop: true,
      controller: 'patientPocHistoryCtrl',
      controllerAs: "ctrl",
      windowClass: "modal modal-slide-in-right patient-modal patient-poc-history-modal",
      resolve: {
        patient: patient,
      }

    });
  };

  $rootScope.openPatientNewTab = function(id) {
    if (!id) return;

    let pathStateWithQuery = $rootScope.getPatientPageURL(id);

    window.open(pathStateWithQuery, "_blank");
  }

  $rootScope.handleOpenPatientModal = function(id, forceModal) {
    if (
      $rootScope.currentOpenedPatientIdModal &&
      $rootScope.currentOpenedPatientIdModal !== id
    ) {
      $rootScope.closePatientModal(true);
    }

    if (!$rootScope.currentOpenedPatientIdModal) {
      $rootScope.currentOpenedPatientIdModal = id;
      $rootScope.patientModalInstance = $uibModal.open({
        templateUrl: 'admin/views/patient-modal.html',
        size: 'lg',
        backdrop: true,
        controller: 'patientCtrl',
        windowClass: "modal modal-slide-in-right patient-modal",
        resolve: {
          id: function() {
            return id;
          },
          forceModal: function() {
            return forceModal;
          }
        }

      });
    }

    $rootScope.patientModalInstance.result.finally(() => {
      const patientParam = getParameterByName('patient');
      const caregiverParam = getParameterByName('caregiver');
      if (caregiverParam) {
        return;
      }
      if ($rootScope.previousOpenedPatientIdModal) {
        $rootScope.openPatientModal($rootScope.previousOpenedPatientIdModal);
        $rootScope.previousOpenedPatientIdModal = undefined;
      } else if (!patientParam) {
        $rootScope.closePatientModal();
      } else {
        $rootScope.currentOpenedPatientIdModal = undefined;
        $state.go(
          $state.current.name, {
            patient: null
          }, {
            reload: false
          }
        );
      }
    });
  };

  $rootScope.closePatientModal = function(saveState = false) {
    if (saveState === true) {
      $rootScope.previousOpenedPatientIdModal = $rootScope.currentOpenedPatientIdModal;
    }
    $rootScope.currentOpenedPatientIdModal = undefined;
    if ($rootScope.patientModalInstance && $rootScope.patientModalInstance.opened.$$state.value) {
      $rootScope.patientModalInstance.close();
    }
  };

  $rootScope.closeCaregiverModal = function() {
    if ($rootScope.caregiverModalInstance && $rootScope.caregiverModalInstance.opened.$$state.value) {
      $rootScope.caregiverModalInstance.close()

      if ($rootScope.previousOpenedPatientIdModal) {
        $rootScope.openPatientModal($rootScope.previousOpenedPatientIdModal);
        $rootScope.previousOpenedPatientIdModal = undefined;
      }
    }
  };

  $rootScope.closePatientPocHistoryModal = function() {
    if ($rootScope.patientPocHistoryModalInstance && $rootScope.patientPocHistoryModalInstance.opened.$$state.value) {
      $rootScope.patientPocHistoryModalInstance.close();
    }
  };

  $rootScope.getVisitInstanceModalUrl = function(id, patientId) {
    if (!id) return;
    const url = window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      `?visitInstance=${id}`;

    return patientId ? `${url}&visitInstancePatient=${patientId}` : url;
  }

  $rootScope.openVisitInstanceModal = function(visitInstanceId, patientId) {
    let pathStateWithQuery = $rootScope.getVisitInstanceModalUrl(visitInstanceId, patientId);

    window.history.pushState({ path: pathStateWithQuery }, "", pathStateWithQuery);

    return $rootScope.handleOpenVisitInstanceModal(visitInstanceId, patientId);
  };

  $rootScope.openVisitInstanceModalNewTab = function(visitInstanceId, patientId) {
    if (!visitInstanceId) return;

    let pathStateWithQuery = $rootScope.getVisitInstanceModalUrl(visitInstanceId, patientId);

    window.open(pathStateWithQuery, "_blank");
  }

  $rootScope.handleOpenVisitInstanceModal = function(visitInstanceId, patientId) {
    $rootScope.visitInstanceModalInstance = $uibModal.open({
        templateUrl: 'admin/views/visit-instance-modal.html',
        size: 'lg',
        controller: 'visitInstanceModalCtrl',
        backdrop: true,
        resolve: {
          visitId: () => visitInstanceId,
          patientId: () => patientId,
        }
      });

    $rootScope.visitInstanceModalInstance.result
        .finally(() => $state.go($state.current.name, { visitInstance: null }, { reload: false }));

    return $rootScope.visitInstanceModalInstance;
  };

  $rootScope.closeVisitInstanceModal = function() {
    if ($rootScope.visitInstanceModalInstance && $rootScope.visitInstanceModalInstance.opened.$$state.value) {
      $rootScope.visitInstanceModalInstance.close()
    }
  };

  $rootScope.openBulkVisitInstancesModal = function(visitInstanceIds) {
    return $uibModal.open({
      templateUrl: 'admin/views/visit-instance-modal.html',
      size: 'lg',
      controller: 'visitInstanceModalCtrl',
      backdrop: true,
      resolve: {
        visitInstanceIds: () => visitInstanceIds
      }
    });
  };

  $rootScope.getCAgencyMemberName = function(id) {
    var coordinators = DatabaseApi.getAgencyMembers();

    if (coordinators[id]) return coordinators[id];
    return '';
  };

  $rootScope.openVisitBroadcastModalById = (visitBroadcastId) => {
    DatabaseApi.getVisit(visitBroadcastId).then((visit) => {
      visit.status = VisitStatus.getVisitStatus(visit);
      $rootScope.openVisitModal({ visit })
    }, (err) => {
      toaster.pop("error", "Oops...", "Can't open visit broadcast modal");
    });
  };

  $rootScope.openVisitModal = ({
    visit
  }) => {
    $uibModal.open({
      templateUrl: 'admin/views/visit-modal.html',
      size: 'lg',
      windowClass: 'modal-slide-in-right visit-modal-window',
      controller: 'visitModalCtrl',
      resolve: {
        visit: () => visit,
      }
    });
  }

  $rootScope.dropdownSettings = dropdownSettingsConsts;

  /*

    $rootScope.countDownSweepTimer = function () {


      var newYork = new Date().toLocaleString('en-US', {hour: '2-digit', hour12: false});
      var local = new Date().toLocaleString('en-US', {hour: '2-digit', hour12: false});


      if (newYork.search(':') > -1) {
        newYork = newYork.split(':')[0].toString();
        local = local.split(':')[0].toString();
      }

      var dif = parseInt(local.replace(/[^\d]/, "")) - parseInt(newYork.replace(/[^\d]/, ""));
      var nowZero = new Date().setHours(dif, 0, 0, 0);


      var end = setDay(new Date(nowZero), 0);

      var _second = 1000;
      var _minute = _second * 60;
      var _hour = _minute * 60;
      var _day = _hour * 24;
      var timer;

      function showRemaining() {
        var now = new Date();
        var distance = end - now;
        //console.log(end);
        //console.log(now);


        if (distance < 0) {

          $rootScope.sweepCounter = {
            days: '00',
            hours: '00',
            minutes: '00',
            seconds: '00'
          };

          return;
        }

        var days = Math.floor(distance / _day);
        var hours = Math.floor((distance % _day) / _hour);
        var minutes = Math.floor((distance % _hour) / _minute);
        var seconds = Math.floor((distance % _minute) / _second);

        if (hours < 10) hours = '0' + hours;
        if (minutes < 10) minutes = '0' + minutes;
        if (seconds < 10) seconds = '0' + seconds;

        $rootScope.sweepCounter = {
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds
        };

        ////console.log($rootScope.sweepCounter);

      }

      $interval(showRemaining, 1000);

    };

    function setDay(date, dayOfWeek) {
      date = new Date(date.getTime());
      date.setDate(date.getDate() + (dayOfWeek + 7 - date.getDay()) % 7);
      return date;
    }

    $rootScope.countDownSweepTimer();
  */


  ////
  ////
  ////


  //  var _second = 1000;
  //  var _minute = _second * 60;
  //  var _hour = _minute * 60;
  //  var _day = _hour * 24;
  //  var timer;

  //  // var twoWeeks = 60 * 60 * 24 * 14 * 1000;
  //  var arbitraryEndTime = new Date(
  //      new Date(
  //          new Date(
  //              new Date(
  //                  new Date(
  //                     new Date()
  //                      .setFullYear(2019))
  //                      .setMonth(8))
  //                      .setDate(30))
  //                      .setHours(0))
  //                      .setMinutes(0))
  //                      .setSeconds(0);

  //  console.log(arbitraryEndTime);
  //  /*while (new Date().getTime() > arbitraryEndTime) {
  //    // arbitraryEndTime = arbitraryEndTime + twoWeeks;
  //  }*/

  //  function countDown() {
  //    var distance = arbitraryEndTime - new Date().getTime();

  //    var days = Math.floor(distance / _day);
  //    var hours = Math.floor((distance % _day) / _hour);
  //    var minutes = Math.floor((distance % _hour) / _minute);
  //    var seconds = Math.floor((distance % _minute) / _second);

  //    if (distance < 0) {
  //      // arbitraryEndTime = arbitraryEndTime + twoWeeks;
  //      return;
  //    }

  //    if (hours < 10) hours = '0' + hours;
  //    if (minutes < 10) minutes = '0' + minutes;
  //    if (seconds < 10) seconds = '0' + seconds;

  //    $rootScope.sweepCounter = {
  //      days: days,
  //      hours: hours,
  //      minutes: minutes,
  //      seconds: seconds
  //    };

  //  };
  //  $interval(countDown, 1000);

  /*

       function countDown() {
         var distance = new Date('08/24/2018') - new Date().getTime();

         var days = Math.floor(distance / _day);
         var hours = Math.floor((distance % _day) / _hour);
         var minutes = Math.floor((distance % _hour) / _minute);
         var seconds = Math.floor((distance % _minute) / _second);

         if (distance < 0) {
           days = '0';
           hours = '00';
           minutes = '00';
           seconds = '00';
           return;
         }

         if (hours < 10) hours = '0' + hours;
         if (minutes < 10) minutes = '0' + minutes;
         if (seconds < 10) seconds = '0' + seconds;

         $rootScope.sweepCounter = {
           days: days,
           hours: hours,
           minutes: minutes,
           seconds: seconds
         };

       };
       $interval(countDown, 1000);

  */


  /*var modalInstance = $uibModal.open({
      templateUrl: 'admin/views/visit-suggest-modal.html',
      size: 'lg',
      controller: 'visitSuggestModalCtrl',
      resolve: {
          visit: function () {
              return {};
          }

      }

  });
  modalInstance.result.then(function (res) {
      console.log('modal click');
      console.log(res);
      //createVisit(res);
  }, function () {
      console.log('modal close');
  });*/

  /*
      $timeout(function () {


          var modalInstance = $uibModal.open({
              templateUrl: 'admin/views/rn-visit-map-modal.html',
              size: 'lg',
              controller: 'rnVisitMapModalCtrl'

          });
          modalInstance.result.then(function (res) {
              console.log('modal out');
              //console.log(res);
          }, function () {
              console.log('modal close');
          });

      },3000);*/

  $rootScope.openOvertimeCalculatorModal = function() {
    $uibModal.open({
      templateUrl: "admin/views/overtime-calculator-modal.html",
      size: 'md',
      controller: 'overtimeCalculatorModalCtrl'
    });
  }

  $rootScope.openNoteCreateModal = function(isBulk) {
    const newScope = $rootScope.$new();
    newScope.isBulk = isBulk;

    const modalInstance = $uibModal.open({
      templateUrl: 'admin/views/create-note-modal.html',
      size: 'md',
      controller: 'createNoteModalCtrl',
      resolve: { },
      scope: newScope,
      windowClass: "modal modal-slide-in-right create-note-container"
    });

    return modalInstance;
  }

  $rootScope.openSavedReportPermissionsModal = function(newScope) {
    const modalInstance = $uibModal.open({
      templateUrl: 'admin/views/saved-report-permissions-modal.html',
      size: 'md',
      controller: 'savedReportPermissionsModalCtrl',
      scope: newScope,
      windowClass: 'modal modal-slide-in-right saved-report-permissions-container',
      backdrop: 'static',
      keyboard: false
    });

    return modalInstance;
  };
  $rootScope.openStandardReportPermissionsModal = function(newScope) {
    const modalInstance = $uibModal.open({
      templateUrl: 'admin/views/standard-report-permissions-modal.html',
      size: 'md',
      controller: 'standardReportPermissionsModalCtrl',
      scope: newScope,
      windowClass: 'modal modal-slide-in-right saved-report-permissions-container',
      backdrop: 'static',
      keyboard: false
    });

    return modalInstance;
  };
  $rootScope.openStandardReportHiddenModal = function(newScope) {
    const modalInstance = $uibModal.open({
      templateUrl: 'admin/views/standard-report-hidden-modal.html',
      size: 'md',
      controller: 'savedReportPermissionsModalCtrl',
      scope: newScope,
      windowClass: 'modal modal-slide-in-right saved-report-permissions-container',
      backdrop: 'static',
      keyboard: false
    });

    return modalInstance;
  };


  $rootScope.openReportEntityPermissionsModal = function() {
    const modalInstance = $uibModal.open({
      templateUrl: 'admin/views/report-entity-permissions-modal.html',
      size: 'md',
      controller: 'reportEntityPermissionsModalCtrl',
      windowClass: 'modal modal-slide-in-right report-entity-permissions-container',
      backdrop: 'static',
      keyboard: false
    });

    return modalInstance;
  };

  $rootScope.openScheduledReportModal = function(newScope) {
    const modalInstance = $uibModal.open({
      templateUrl: 'admin/views/scheduled-report-modal.html',
      size: 'md',
      controller: 'scheduledReportModalCtrl',
      scope: newScope,
      windowClass: 'modal modal-slide-in-right scheduled-report-container',
      backdrop: 'static',
      keyboard: false
    });

    return modalInstance;
  }

  $rootScope.navigationItems = {
    dashboard: {
      title: 'Dashboard',
      stateName: 'app.dashboard',
      sectionName: 'dashboard',
      iconPath: 'admin/images/icons/dashboard.svg',
      permissionKey: 'view_dashboard_section',
      subItems: [{
          text: 'Caregivers',
          stateName: 'app.dashboard.caregivers',
          permissionKey: 'view_dashboard_section_caregivers'
        },
        {
          text: 'Online',
          stateName: 'app.dashboard.online',
          permissionKey: 'view_dashboard_section_online'
        },
        {
          text: 'Installations',
          stateName: 'app.dashboard.installations',
          permissionKey: 'view_dashboard_section_installations'
        },
        {
          text: 'Tutorials',
          stateName: 'app.tutorials',
          permissionKey: 'view_tutorials_section'
        },
        {
          text: 'Visit Statistics',
          stateName: 'app.dashboard.visitStatistics',
          permissionKey: 'view_dashboard_section_statistics'
        },
        {
          text: 'Coordinator Activity',
          stateName: 'app.dashboard.coordinatorActivity',
          permissionKey: 'view_dashboard_section_coordinator'
        }
      ]
    },
    notes: {
      title: 'Notes',
      label: "unread_count",
      stateName: 'app.notes',
      sectionName: 'notes',
      iconPath: 'admin/images/icons/notes-light.svg',
      subItems: [],
      permissionKey: 'view_notes_section'
    },
    recruitment: {
      title: 'Recruitment',
      stateName: 'app.recruitment',
      sectionName: 'recruitment',
      iconPath: 'admin/images/icons/recruitment.svg',
      subItems: [],
      permissionKey: 'view_recruitment_section'
    },
    passportVerifier: {
      title: 'Passport Verifier',
      stateName: 'app.passportVerifier',
      sectionName: 'passportVerifier',
      iconPath: 'admin/images/icons/medflyt-passport.svg',
      subItems: [],
      permissionKey: 'view_passport_verifier_section'
    },
    passport: {
      title: 'Passport',
      stateName: 'app.passport',
      sectionName: 'passport',
      iconPath: 'admin/images/icons/medflyt-passport.svg',
      subItems: [],
      permissionKey: 'view_passport_section'
    },
    patients: {
      title: 'Patients',
      stateName: 'app.patients',
      sectionName: 'patients',
      iconPath: 'admin/images/icons/patients.svg',
      permissionKey: 'view_patients_section',
      subItems: [{
          text: 'Patients list',
          permissionKey: 'view_patients_section_list',
          stateName: 'app.patients.dashboard'
        },
        {
          text: 'Care & Task management',
          permissionKey: 'view_patients_section_upcoming_tasks',
          stateName: 'app.patients.careAndTaskManagement'
        },
        {
          text: 'Patient Alerts',
          permissionKey: 'view_patients_section_alerts',
          stateName: 'app.patients.patientAlerts'
        },
        {
          text: 'Quality Of Care',
          permissionKey: 'view_patients_section_medical_statistics',
          stateName: 'app.patients.medicalStatistics'
        },
        {
          text: 'Eligibility Checks',
          permissionKey: 'view_eligibility_checks',
          stateName: 'app.patients.eligibilityChecks',
          releaseDate: LocalDate.of(2022, 4, 15)
        }
      ]
    },
    caregivers: {
      title: 'Caregivers',
      stateName: 'app.professionals',
      sectionName: 'professionals',
      iconPath: 'admin/images/icons/caregivers.svg',
      permissionKey: 'view_caregivers_section',
      subItems: [{
          text: 'Caregivers list',
          permissionKey: 'view_caregivers_section_list',
          stateName: 'app.professionals.caregivers'
        },
        {
          text: 'Pending Acceptance',
          stateName: 'app.professionals.pendingAcceptance',
          permissionKey: 'view_caregivers_section_pending_acceptance',
          showViewByUserRole: 'pending'
        },
        {
          text: 'Onboarding',
          stateName: 'app.onboarding',
          permissionKey: 'view_caregivers_section_list',
        },
        {
          text: 'Compliance Tracking',
          permissionKey: 'view_caregivers_section_compliance',
          stateName: 'app.caregiversCompliance',
          releaseDate: LocalDate.of(2022, 5, 10),
        },
        {
          text: 'Caregivers Passport',
          permissionKey: 'view_caregivers_section_passport',
          stateName: 'app.passport'
        },
        // {
        //     text: 'PTO Approvals',
        //     stateName: 'app.ptoApprovals',
        //     permissionKey: 'view_caregivers_section_pto_approvals',
        //     billing: true
        // }
        {
          text: 'Exclusions',
          stateName: 'app.exclusionLists',
          permissionKey: 'view_caregivers_section_list',
        },
      ]
    },
    trainingCenter: {
      title: 'Training Center',
      stateName: 'app.trainingCenter',
      sectionName: 'trainingCenter',
      iconPath: 'admin/images/icons/training-center.svg',
      permissionKey: 'view_caregivers_section_training_overview', // TODO change
      subItems: [{
          text: 'In-Service Overview',
          permissionKey: 'view_caregivers_section_training_overview',
          stateName: 'app.trainingCenter.caregiversTrainingCenterOverview'
        },
        {
          text: 'Enrollments Requests',
          stateName: 'app.trainingCenter.enrollmentsRequests'
        },
        {
          text: 'Active Bundles',
          stateName: 'app.trainingCenter.activeBundles'
        },
        {
          text: 'Orientation',
          stateName: 'app.orientationCenter'
        },
        {
          text: 'Bulk Exempts',
          stateName: 'app.trainingCenterExempts',
          permissionKey: 'view_caregiver_page_training_center'
        },
        // {
        //     text: 'Live Events',
        //     stateName: 'app.trainingCenter.liveEvents'
        // }
      ]
    },
    visits: {
      title: 'Visits',
      stateName: 'app.visits',
      sectionName: 'visits',
      iconPath: 'admin/images/icons/visits.svg',
      permissionKey: 'view_visits_section',
      subItems: [{
          text: 'Broadcasting activity',
          permissionKey: 'view_visits_section_list',
          stateName: 'app.visits.dashboard'
        },
        {
          text: 'Visit instances',
          permissionKey: 'view_visits_section_instances',
          stateName: 'app.visitInstances',
          billing: true,
          showViewByUserRole: 'visitInstances'
        },
        {
          text: 'Upload visit',
          permissionKey: 'view_visits_section_upload',
          stateName: 'app.visits.upload',
          hideForBilling: true,
        },
        {
          text: 'Duty Sheets',
          permissionKey: 'view_visits_section_duty_sheets',
          stateName: 'app.dutySheets'
        },
        {
          text: 'Time Sheets Approval',
          permissionKey: 'view_visits_section_duty_sheets',
          stateName: 'app.dutySheetsApprovals'
        },
        {
          text: 'Staffing Issues',
          permissionKey: 'view_visits_section_staffing_issues',
          stateName: 'app.staffingIssues'
        },
        {
          text: "Hours Usage",
          permissionKey: 'view_visits_section_staffing_issues',
          stateName: "app.hoursUsage"
        }
      ]
    },
    evv: {
      title: 'EVV',
      stateName: 'app.evv',
      sectionName: 'evv', // TODO change
      iconPath: 'admin/images/icons/call-darker.svg',
      permissionKey: 'view_visits_section', // TODO change
      subItems: [{
          text: 'Clock in & out',
          permissionKey: 'view_visits_section_clockin',
          stateName: 'app.evv.clockedVisits',
          allowedAgencies: [1, 7, 373, 1552, 3862, 4093, 3235, 5149, 5215, 4621, 1057, 5182, 6040, 5678, 2806, 5644, 6205, 6238, 5974, 4357, 6634, 4489]
        },
        {
          text: 'Calls',
          permissionKey: 'view_visits_section_clockin', // TODO change
          stateName: 'app.evv.calls',
          allowedAgencies: [1, 7, 373, 1552, 3862, 4093, 3235, 5149, 5215, 4621, 1057, 5182, 6040, 5678, 2806, 5644, 6205, 6238, 5974, 4357, 6634, 4489]
        }
      ]
    },
    payroll: {
      title: 'Payroll',
      stateName: 'app.payroll',
      sectionName: 'payroll', // TODO change
      iconPath: 'admin/images/icons/price-smaller.svg',
      permissionKey: 'view_payroll_section', // TODO change
      subItems: [{
          text: 'Payroll Batches',
          permissionKey: 'view_payroll_section_batches',
          stateName: 'app.payroll.batches',
        },
        {
          text: 'PTO Approvals',
          stateName: 'app.payroll.ptoApprovals',
          permissionKey: 'view_caregivers_section_pto_approvals'
        }
      ]
    },
    billing: {
      title: 'Billing',
      stateName: 'app.billing',
      sectionName: 'billing',
      iconPath: 'admin/images/icons/billing.svg',
      permissionKey: 'view_billing_section',
      subItems: [{
          text: 'Visits with issues',
          permissionKey: 'view_billing_section_visit_with_issues',
          stateName: 'app.billing.visitsWithIssues'
        },
        {
          text: 'Billable visits',
          permissionKey: 'view_billing_section_billable_visits',
          stateName: 'app.billing.newInvoiceBatch'
        },
        {
          text: 'Claims',
          permissionKey: 'view_billing_section_invoices',
          stateName: 'app.billing.invoices'
        },
        {
          text: 'Adjustment Approvals',
          permissionKey: 'view_billing_section_adjustment_approvals',
          stateName: 'app.billing.visitAdjustmentApprovals'
        },
        {
          text: 'Visits to export',
          permissionKey: 'view_billing_section_visits_to_export',
          stateName: 'app.billing.visitsToExport'
        },
        {
          text: 'Exports',
          permissionKey: 'view_billing_section_exports',
          stateName: 'app.billing.exports'
        },
        {
          text: 'Checks',
          permissionKey: 'view_billing_section_checks',
          stateName: 'app.billing.checks'
        },
        {
          text: 'AR',
          permissionKey: 'view_billing_section_ar',
          stateName: 'app.billing.accountsReceivable'
        },
        {
          text: '837 EDI Comparison',
          permissionKey: 'view_billing_section_837_edi',
          stateName: 'app.billing.ediComparison'
        }
        // {
        //     text: '835 EDI Conversion',
        //     permissionKey: 'view_billing_section_835_edi',
        //     stateName: 'app.billing.claimsConversion'
        // },

      ]
    },
    calls: {
      title: 'Calls Dashboard',
      stateName: 'app.calls',
      sectionName: 'calls',
      iconPath: 'admin/images/icons/calls-management.svg',
      permissionKey: 'view_calls_section',
      subItems: [{
          text: 'Calls Maintenace',
          permissionKey: 'view_calls_section_maintenance',
          stateName: 'app.calls.maintenance'
        },
        {
          text: 'Rejected Calls',
          permissionKey: 'view_calls_section_rejected',
          stateName: 'app.calls.rejected'
        },
        {
          text: 'Missed In',
          permissionKey: 'view_calls_section_missed_in',
          stateName: 'app.calls.missedIn'
        },
        {
          text: 'Missed Out',
          permissionKey: 'view_calls_section_missed_out',
          stateName: 'app.calls.missedOut'
        },
        {
          text: 'Missed Both',
          permissionKey: 'view_calls_section_missed_both',
          stateName: 'app.calls.missedBoth'
        }
      ]
    },
    visitEditRequests: {
      title: 'Edit Requests',
      stateName: 'app.visitEditRequests',
      sectionName: 'visitEditRequests',
      iconPath: 'admin/images/icons/edit-requests.svg',
      subItems: [],
      permissionKey: 'view_edit_requests_section'
    },
    communication: {
      title: 'Chat',
      stateName: 'app.communication',
      sectionName: 'communication',
      iconPath: 'admin/images/icons/communication.svg',
      subItems: [],
      permissionKey: 'view_communication_section'
      //     {
      //         text: 'Chat',
      //         stateName: 'app.communication'
      //     },
      //     {
      //         text: 'Broadcast',
      //         // stateName: 'app.communication'
      //     },
      //     {
      //         text: 'Boost App Downloads',
      //         // stateName: 'app.communication'
      //     }
      // ]
    },
    commcenter: {
      title: 'Comm Center',
      stateName: 'app.commcenter',
      sectionName: 'commcenter',
      iconPath: 'admin/images/icons/broadcast.svg',
      subItems: [],
      permissionKey: 'view_communication_center'
    },
    // analytics: {
    //     title: 'Analytics',
    //     stateName: 'app.analyticReports',
    //     sectionName: 'analytics',
    //     iconPath: 'admin/images/icons/analytics.svg',
    //     permissionKey: 'view_analytics_section',
    //     subItems: []
    // },
    admin: {
      title: 'Admin',
      stateName: 'app.settings',
      sectionName: 'admin',
      iconPath: 'admin/images/icons/admin.svg',
      subItems: [{
          text: 'Visit Settings',
          stateName: 'app.settings',
          permissionKey: 'view_admin_section_settings'
        },
        {
          text: 'HR Settings',
          stateName: 'app.hrSettings',
          showViewByUserRole: 'hrSettings',
          permissionKey: 'view_admin_section_hr'
        },
        {
          text: 'Notes Settings',
          stateName: 'app.notesSettings',
          permissionKey: 'view_admin_section_notes'
        },
        {
          text: 'Training Center Settings',
          stateName: 'app.trainingCenterSettings',
          showViewByUserRole: 'hrSettings',
          permissionKey: 'view_admin_section_training'
        },
        {
          text: 'Clinical & Care settings',
          stateName: 'app.patientDocumentSettings',
          permissionKey: 'view_admin_section_patient_documents'
        },
        {
          text: 'Team Members',
          stateName: 'app.invite',
          permissionKey: 'view_admin_section_members'
        },
        {
          text: 'Flyers',
          stateName: 'app.flyers',
          permissionKey: 'view_admin_section_flyers'
        },
        {
          text: 'Billing Info',
          stateName: 'app.payment',
          permissionKey: 'view_admin_section_billing'
        },
        {
          text: 'Analytics',
          stateName: 'app.analyticReports',
          permissionKey: 'view_analytics_section'
        },
      ],
      permissionKey: 'view_admin_section',
    },
    reports: {
      title: 'Reports',
      stateName: 'app.reports',
      sectionName: 'reports',
      iconPath: 'admin/images/icons/reports.svg',
      permissionKey: 'view_reports_section',
      subItems: [
        {
          text: 'Standard',
          permissionKey: 'view_reports_section_standard',
          stateName: 'app.reports.standard',
          billing: true

        },
        {
            text: 'Advanced',
            permissionKey: 'view_reports_section_advanced',
            stateName: 'app.reports.savedReports',
            billing: true,
            allowedAgencies: [5743, 5512, 5380]

          },
        {
          text: 'COVID-19 Reports',
          permissionKey: 'view_covid_section',
          stateName: 'app.reports.coronaVirusReports'
        }

      ]
    },
    agencies: {
      title: 'Agencies',
      stateName: 'app.agencies',
      sectionName: 'agencies',
      iconPath: 'admin/images/icons/engineering.svg',
      permissionKey: 'view_agencies_section',
      subItems: [{
          text: 'Customer Engagement',
          stateName: 'app.agencies.customerEngagement',
          permissionKey: 'view_agencies_section_customer_engagement'
        },
        {
          text: 'Engagement kpi',
          stateName: 'app.agencies.engagementKpi',
          permissionKey: 'view_agencies_section_engagement_kpi'
        }
      ]
    },
    hhaxIntegration:{
      title: 'HHAX Integration',
      stateName: 'app.hhax',
      sectionName: 'hhaxIntegration',
      iconPath: 'admin/images/icons/import.svg',
      permissionKey: 'view_hhax_integration_section',
      releaseDate: LocalDate.of(2022, 4, 12),
      subItems: [{
        text: 'Billing Rejections',
        stateName: 'app.hhax.billing',
        permissionKey: 'view_hhax_integration_section_billing'
      },
      {
        text: 'Non-Billing Records',
        stateName: 'app.hhax.nonBilling',
        permissionKey: 'view_hhax_integration_section_non_billing'
      },
      {
        text: 'Unlinked Patients',
        stateName: 'app.hhax.unlinkedPatients',
        permissionKey: 'view_hhax_integration_section_non_billing'
      }]
    },
  }

  function checkIsOnboardingAgencyId() {
    if (import.meta.env.MEDFLYT_AGENCY_ID) {
        return parseInt(import.meta.env.MEDFLYT_AGENCY_ID, 10) === $rootScope.agencyId
    }

    return $rootScope.agencyId === 6205;
  }

  $rootScope.isOnboardingAgency = checkIsOnboardingAgencyId();

  $rootScope.$watch("agencyId", () => {
    $rootScope.isOnboardingAgency = checkIsOnboardingAgencyId();

    if ($rootScope.isOnboardingAgency) {
      $rootScope.navigationItems.caregivers.subItems.push({
        text: "Prioritized caregivers",
        stateName: "app.caregiversPrioritized",
      });

      $rootScope.navigationItems.complianceDocumentReview = {
        title: "Compliance Document Review",
        stateName: "app.complianceDocumentReview",
        sectionName: "complianceDocumentReview",
        iconPath: "admin/images/icons/notes-dark.svg",
        permissionKey: "review_caregiver_compliance_document",
        subItems: [
          {
            text: "Compliance Document Review",
            stateName: "app.complianceDocumentReview.complianceDocumentReview",
            permissionKey: "review_caregiver_compliance_document",
          },
        ],
      };
    }
  })

  $rootScope.sidenavHover = function(section) {
    $rootScope.sidenavHoveredSection = section || '';
  }

  $rootScope.sidenavFocus = function(section, stopPropagation) {
    if (stopPropagation) {
      event.stopPropagation();
    }
    if (!section || $rootScope.sidenavFocusedSection === section) {
      $rootScope.sidenavFocusedSection = '';
    } else {
      $rootScope.sidenavFocusedSection = section;
    }
  }

  var resetFocus = function() {
    var pathArr = $location.$$path.split('/');
    if (pathArr.length < 3) {
      $rootScope.sidenavFocus();
    } else {
      $rootScope.sidenavFocus(pathArr[2]);
    }
  }

  $rootScope.sidenavHover();
  resetFocus();

  $rootScope.settingsMouseenterHandler = function() {
    $rootScope.isSettingsHovered = true;
  }

  $rootScope.settingsMouseleaveHandler = function() {
    $rootScope.isSettingsHovered = false;
  }

  $rootScope.goToDashboard = function() {
    $state.go('app.dashboard');
  }

  $rootScope.goTo = function(state) {
    $state.go(state, {}, {
      reload: true
    });
  }

  $rootScope.isPermittedByKey = function(permissionKey) {
    const foundPermission = $rootScope.user.permissions.find(permission => permission === permissionKey);
    return foundPermission !== undefined
  }


  $rootScope.dateFormat = "MM/dd/yyyy"
  $rootScope.dateMaskFormat = "99/99/9999"

  $rootScope.daysOfWeekShortMap = [
    { id: DayOfWeek.valueOf("MONDAY"), label: "Mo" },
    { id: DayOfWeek.valueOf("TUESDAY"), label: "Tu" },
    { id: DayOfWeek.valueOf("WEDNESDAY"), label: "We" },
    { id: DayOfWeek.valueOf("THURSDAY"), label: "Th" },
    { id: DayOfWeek.valueOf("FRIDAY"), label: "Fr" },
    { id: DayOfWeek.valueOf("SATURDAY"), label: "Sa" },
    { id: DayOfWeek.valueOf("SUNDAY"), label: "Su" }
  ];

  // When imported as a <script> tag, ChartDataLabels is being used the globally
  // So we must unregister it from all charts and add it manually to the wanted ones.
  Chart.unregister(ChartDataLabels);

  let isActiveIncrement = 0;
  document.addEventListener("keypress", (e) => {
    if (e.key === "`") {
      isActiveIncrement++;
    }

    if (isActiveIncrement >= 10) {
        isActiveIncrement = 0;
        $rootScope.openApiOverrideModal();
    }
  });
  // DEV-4926 Disable input change when scrolling and the cursor focus is on <input type="number" />
  document.addEventListener("wheel", (e) => {
    if (document.activeElement.type === "number") {
      document.activeElement.blur();
    }
  });

  $rootScope.openApiOverrideModal = () => {
    $uibModal.open({
        templateUrl: 'admin/views/switch-env.html',
        size: 'md',
        controller: 'switchEnv',
        windowClass: "switch-env-container center-center",
        backdrop: true,
        keyboard: true,
    });
  }

  $rootScope.resetApiOverride = () => {
      localStorage.removeItem("apiOverride");
      localStorage.removeItem("user");
      localStorage.removeItem("userEmail");
      window.location.reload();
  }

  $rootScope.checkDisplayBanner = function (time) {
    const today = LocalDate.now();
    return time && time.plusDays(21).isAfter(today);
  };

};
